export const LOOKING_INFO = {
  pt: "Buscando as informações",
  es: "Buscando las informaciones",
  en: "Looking for information",
};
export const Available = {
  pt: "Disponível",
  es: "Disponible",
  en: "Available",
};
export const Charging = {
  pt: "Carregando",
  es: "Cargando",
  en: "Charging",
};
export const Reserved = {
  pt: "Reservada",
  es: "Reservada",
  en: "Reserved",
};
export const Faulted = {
  pt: "Com erro",
  es: "Con erro",
  en: "With error",
};
export const Unavailable = {
  pt: "Indisponível",
  es: "No disponible",
  en: "Not available",
};
export const Preparing = {
  pt: "Preparando",
  es: "Preparando",
  en: "Priming",
};
export const Finishing = {
  pt: "Finalizando",
  es: "Finalizando",
  en: "Finishing",
};
export const SuspendedEV = {
  pt: "(EV) Suspenso",
  es: "(EV) Suspendido",
  en: "(EV) Suspended",
};
export const SuspendedEVSE = {
  pt: "(EVSE) Suspenso",
  es: "(EVSE) Suspendido",
  en: "(EVSE) Suspended",
};
export const ThirdParty = {
  pt: "Terceiros",
  es: "Terceros",
  en: "Third Parties",
};
export const Disabled = {
  pt: "Desativada",
  es: "Desactivada",
  en: "Disabled",
};
export const Test = {
  pt: "Teste",
  es: "Prueba",
  en: "Test",
};
export const Upkeep = {
  pt: "Em manutenção",
  es: "En mantenimiento",
  en: "Under maintenance",
};
export const ToInstall = {
  pt: "Para instalar",
  es: "Para instalar",
  en: "To install",
};
export const Loading = {
  pt: "Aguarde...",
  es: "Espere...",
  en: "Wait...",
};
