import {
  Dropdown,
  Menu,
  Modal,
  notification,
  Table,
  Button,
  Form,
  Input,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import apiOcpp from "../../../../services/apiOcpp";
import api from "../../../../services/api";
import "./index.css";
import { formatters } from "../../../../utils/formatters";
// import EditDrawer from "./EditDrawer";
import moment from "moment-timezone";
import dots from "../../../../assets/dots.png";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { FreireContext } from "../../../../utils/freireContext";
import * as texts from "../../locales";

const { confirm } = Modal;

class ShowTransaction extends Component {
  static contextType = FreireContext;

  state = {
    loading: false,
    meterValues: [],
    id: null,
    transaction: {},
    chargerHistory: {},
    remoteCharge: {},
  };

  async componentDidMount() {
    this.getTransaction();
  }

  async getTransaction(params = {}) {
    const { freire } = this.context;

    try {
      const { id } = this.props.match.params;

      this.setState({ id, loading: true });

      const { data: transaction } = await apiOcpp.get(`transaction/${id}`, {
        params,
      });

      const { data: chargerHistory } = await api.get(`chargerHistory/${id}`);

      this.setState({
        transaction,
        chargerHistory,
        meterValues: transaction?.meterValues || [],
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.NOT_FIND_CHARGE_BY_ID),
      });
    }
  }

  menu = (stationId, id, freire) => (
    <Menu>
      <Menu.Item onClick={() => this.showConfirm(stationId, id)}>
        <span>{freire(texts.STOP)}</span>
      </Menu.Item>
    </Menu>
  );

  timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async showConfirm(stationId, id) {
    const { freire } = this.context;

    confirm({
      title: freire(texts.CONFIRM_SEND_STOP_COMMAND),
      content: freire(texts.CONFIRM_SEND_STOP_COMMAND_DESCRIPTION),
      onOk: async () => {
        try {
          const { data } = await apiOcpp.post(
            `ocpp16/remoteStopTransaction/${stationId}/transaction/${id}`
          );
          if (data.result === "Accepted") {
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
            await this.timeout(3000);

            this.getTransaction();
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
          }
        } catch (error) {
          notification.error({
            message: freire(texts.OOPS),
            description: freire(texts.UNEXPECTED),
          });
        }
      },
      onCancel() {},
    });
  }

  async attachRemoteChargeConfirm() {
    const { freire } = this.context;
    const { transaction, remoteCharge } = this.state;

    confirm({
      title: freire(texts.CONFIRM_ATTACH_REMOTE_CHARGE),
      content: freire(texts.CONFIRM_ATTACH_REMOTE_CHARGE_DESCRIPTION),
      onOk: async () => {
        try {
          let success = false;
          let chargerHistory;
          if (!remoteCharge.email) {
            notification.error({
              message: freire(texts.OOPS),
              description: freire(texts.VERIFY_AGAIN),
            });
            return;
          }
          const resp = await api.post("/admin/create-remote-charge", {
            transactionID: transaction.id,
            email: remoteCharge.email?.trim(),
            couponCod: remoteCharge.couponCod?.trim(),
          });
          success = resp.data.success;
          chargerHistory = resp.data.chargerHistory[0];
          var description = freire(texts.CHARGE_ATTACHED);
          if (transaction.id) {
            description += ` ID: ${transaction.id}`;
          }
          if (success) {
            description += ` ${freire(texts.ATTACH_REMOTE_CHARGE_SUCCESS)} ${
              chargerHistory.userEmail
            }`;
            if (chargerHistory.stopDateTime == null) {
              description += ` ${freire(
                texts.CHARGE_HISTORY_CURRENTLY_CHARGING
              )}`;
            }
          } else {
            description += ` ${freire(texts.ATTACH_REMOTE_CHARGE_FAILED)}`;
          }
          notification.success({
            message: freire(texts.ALL_RIGHT),
            description,
          });
          this.setState({ chargerHistory })
        } catch (error) {
          console.error(error);
          notification.error({
            message: freire(texts.OOPS),
            description: freire(texts.VERIFY_AGAIN),
          });
          return;
        }
      },
      onCancel() {},
    });
  }

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.TIME),
        dataIndex: "timestamp",
        key: "timestamp",
        render: (timestamp, data) => {
          return (
            <div style={{ flexDirection: "column" }}>
              <div>
                {(timestamp && moment(timestamp).format("L LTS")) || "----"}
              </div>
            </div>
          );
        },
      },
      {
        title: freire(texts.VALUE),
        dataIndex: "value",
        key: "value",
        render: (value, data) => {
          return <div>{value || "----"}</div>;
        },
      },
      {
        title: freire(texts.RAW_VALUE),
        dataIndex: "rawValue",
        key: "rawValue",
        render: (rawValue, data) => {
          return <div>{rawValue || "----"}</div>;
        },
      },
      {
        title: freire(texts.UNIT),
        dataIndex: "unit",
        key: "unit",
        render: (unit, data) => {
          return <div>{unit || "----"}</div>;
        },
      },
      {
        title: freire(texts.CONTEXT),
        dataIndex: "context",
        key: "context",
        render: (context, data) => {
          return <div>{context || "----"}</div>;
        },
      },
      {
        title: freire(texts.FORMAT),
        dataIndex: "format",
        key: "format",
        render: (format, data) => {
          return <div>{format || "----"}</div>;
        },
      },
      {
        title: freire(texts.MEASURAND),
        dataIndex: "measurand",
        key: "measurand",
        render: (measurand, data) => {
          return <div>{measurand || "----"}</div>;
        },
      },
      {
        title: freire(texts.LOCATION),
        dataIndex: "location",
        key: "location",
        render: (location, data) => {
          return <div>{location || "----"}</div>;
        },
      },
      {
        title: freire(texts.PHASE),
        dataIndex: "phase",
        key: "phase",
        render: (phase, data) => {
          return <div>{phase || "----"}</div>;
        },
      },
    ];
  };

  render() {
    const { meterValues, loading, transaction, chargerHistory } = this.state;
    const { freire } = this.context;

    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.OCPP),
            freire(texts.CHARGE),
          ]}
        />

        <button
          type="button"
          className="displayTop"
          onClick={() =>
            this.props.history.push(`/dashboard/ocpp/transactions`)
          }
        >
          <div className="left"></div>
          <div className="backTitle">{freire(texts.GOBACK_CHARGES)}</div>
        </button>

        <div className="displayInfos">
          <div className="displayDiv">
            <div className="title">{freire(texts.CHARGE_ID)}</div>
            <div className="subtitle">
              {!transaction.stopValue ? <div className="Charging" /> : null}{" "}
              {transaction.id}
            </div>
          </div>
          <div className="displayDiv">
            <div className="title">{freire(texts.STATION_ID)}</div>
            <div className="subtitle">{transaction.stationId}</div>
          </div>
          <div className="displayDiv">
            <div className="title">{freire(texts.CONNECTOR_ID)}</div>
            <div className="subtitle">{transaction.connectorId}</div>
          </div>
          <div className="displayDiv">
            <div className="title">{freire(texts.START_VALUE)}</div>
            <div className="subtitle">
              {transaction.startValue ? String(transaction.startValue) : ""}
            </div>
          </div>
          {transaction.stopValue && (
            <div className="displayDiv">
              <div className="title">{freire(texts.STOP_VALUE)}</div>
              <div className="subtitle">
                {transaction.stopValue ? String(transaction.stopValue) : ""}
              </div>
            </div>
          )}
          <div className="displayDiv">
            <div className="title">{freire(texts.STARTED_AT)}</div>
            <div className="subtitle">
              {transaction.startTimestamp
                ? moment(transaction.startTimestamp).format("L LTS")
                : ""}
            </div>
          </div>
          {transaction.stopValue && (
            <div className="displayDiv">
              <div className="title">{freire(texts.STOPED_AT)}</div>
              <div className="subtitle">
                {transaction.startTimestamp
                  ? moment(transaction.stopTimestamp).format("L LTS")
                  : ""}
              </div>
            </div>
          )}
          {!transaction.stopValue ? (
            <div className="displayDiv">
              <div className="title"></div>
              <Dropdown
                overlay={this.menu(
                  transaction.stationId,
                  transaction.id,
                  freire
                )}
              >
                <div>
                  <img src={dots} style={{ width: 21 }} alt="" />
                </div>
              </Dropdown>
            </div>
          ) : null}
        </div>
        <div className="displayChargerHistoryInfos">
          <div className="displayDiv">
            <div className="title">{freire(texts.USER_EMAIL)}</div>
            <div className="subtitle">
              {chargerHistory == null ? (
                <Input
                  onChange={(value) => {
                    let remoteCharge = this.state.remoteCharge;
                    remoteCharge.email = value.target.value;
                    this.setState({ remoteCharge });
                  }}
                />
              ) : (
                chargerHistory.userEmail
              )}
            </div>
          </div>
          <div className="displayDiv">
            <div className="title">{freire(texts.USER_COUPON_TO_USE)}</div>
            <div className="subtitle">
              {chargerHistory == null ? (
                <Input
                  onChange={(value) => {
                    let remoteCharge = this.state.remoteCharge;
                    remoteCharge.couponCod = value.target.value;
                    this.setState({ remoteCharge });
                  }}
                />
              ) : (
                chargerHistory.coupon?.cod || freire(texts.NO_COUPON)
              )}
            </div>
          </div>
          {chargerHistory != null ? (
            <div className="displayDiv">
              <div className="title">{freire(texts.CHARGE_VALUE)}</div>
              <div className="subtitle">
                {
                  chargerHistory.chargeValue !== undefined
                  ? formatters.currency(
                      freire.userLanguage,
                      chargerHistory.currency
                    )(chargerHistory.chargeValue / 100)
                  : "---"
                }
              </div>
            </div>
          ) : null}
          {chargerHistory != null ? (
            <div className="displayDiv">
              <div className="title">{freire(texts.CHARGE_PAID)}</div>
              <div className="subtitle">
                {chargerHistory.paymentSuccess ? freire(texts.YES) : freire(texts.NO)}
              </div>
            </div>
          ) : null}
          {chargerHistory == null ? (
            <div className="displayDiv">
              <div className="title">
                {freire(texts.ATTACH_REMOTE_CHARGE_BUTTON)}
              </div>
              <div className="subtitle">
                <Button
                  className="attachButton"
                  onClick={() => this.attachRemoteChargeConfirm()}
                >
                  {freire(texts.ATTACH_REMOTE_CHARGE_LABEL)}
                </Button>
              </div>
            </div>
          ) : null}
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          scroll={{ x: 1300 }}
          tableLayout="auto"
          loading={loading}
          size="small"
          pagination={false}
          rowKey={(data) => data.id}
          dataSource={meterValues}
          columns={columns}
        />
      </div>
    );
  }
}

const PageShowTransaction = Form.create({ name: "attachRemoteCharge" })(
  ShowTransaction
);
export default withRouter(PageShowTransaction);
