export const PAYMENT_ON_CHARGE = {
  pt: "Cobrança na carga",
  es: "Cobranza en la carga",
  en: "Payment on charge",
}

export const BUTTON_KEEP = {
  pt: "Manter atual",
  es: "Mantener actual",
  en: "Keep current",
}

export const BUTTON_CHANGE = {
  pt: "Modificar",
  es: "Cambiar",
  en: "Change",
}

export const PAYMENT_CHARGE = {
  pt: "Aceita pagamento na carga",
  es: "Acepta pago en la carga",
  en: "Accpets payment on charge",
};

export const YES = {
  pt: "Sim",
  es: "SÍ",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const VOUCHER_ON_CHARGE = {
  pt: "Cupom na carga",
  es: "Cupon en la carga",
  en: "Voucher on charge",
}

export const COUPON_ON_CHARGE = {
  pt: "Cupom na carga",
  es: "Cupon en la carga",
  en: "Voucher on charge",
}

export const POST_PAYMENT_CHARGE = {
  pt: "Cobrança no caixa",
  es: "Cobranza en caja",
  en: "Payment at the cashier",
}

export const ACCEPT_COUPONS_CHARGE = {
  pt: "Aceita cupom na carga",
  es: "Acepta cupón en la carga",
  en: "Accepts voucher on charge",
};

export const POS_PAYMENT = {
  pt: "Aceita pagamento no caixa",
  es: "Acepta pago en caja",
  en: "Accepts payment at the cashier",
};

export const METHOD = {
  pt: "Modelo de cobrança",
  en: "Payment method",
  es: "Modelo de cobranza",
};

export const METHOD_KWH = {
  pt: "Por kWh",
  en: "By kWh",
  es: "Por kWh",
};

export const METHOD_TIME = {
  pt: "Por tempo",
  en: "By time",
  es: "Por tiempo",
};

export const METHOD_FIXED = {
  pt: "Valor fixo",
  en: "Fixed value",
  es: "Valor fijo",
};

export const VALUE = {
  pt: "Valor",
  es: "Valor",
  en: "Value",
};

export const OF_KWH = {
  pt: "do kWh",
  es: "de kWh",
  en: "of kWh",
};

export const FIXED = {
  pt: "Fixo",
  es: "Fijo",
  en: "Fixed",
};

export const VALUE_EX = {
  pt: "Ex.: 5,00",
  es: "p.ej.: 5,00",
  en: "E.g.: 5,00",
};

export const METHOD_CHARGE = {
  pt: "Cobrança",
  en: "Charge",
  es: "Cobranza",
};

export const METHOD_TIME_RELATIVE = {
  pt: "Proporcional por período de tempo",
  en: "Proportional by time period",
  es: "Proporcional por tamaño de tiempo",
};

export const METHOD_TIME_FIXED = {
  pt: "Fixa por período de tempo",
  en: "Fixed by time period",
  es: "Fixa por tamaño de tiempo",
};


export const PAYMENT_PERIOD = {
  pt: "Período de tempo",
  en: "Time period",
  es: "Período de tiempo",
};

export const METHOD_DESCRIPTION = {
  pt: "a cada",
  en: "every",
  es: "a cada",
};

export const METHOD_TIME_MIN = {
  pt: "Minuto(s)",
  en: "Minute(s)",
  es: "Minuto(s)",
};

export const METHOD_TIME_HOUR = {
  pt: "Hora(s)",
  en: "Hour(s)",
  es: "Hora(s)",
};

export const PAYMENT_CHARGE_RESUME = {
  pt: "Resumo da cobrança",
  en: "Charge resume",
  es: "Resumen de la cobranza",
};

export const COUPONS_PAYMENT = {
  pt: "PAGAMENTO",
  en: "PAYMENT",
  es: "PAGAMENTO",
};

export const CHARGE = {
  pt: "Carga",
  es: "Carga",
  en: "Charge",
};

export const UPDATE_PRICES = {
  pt: "ATUALIZAR PREÇOS",
  es: "ACTUALIZAR PRECIOS",
  en: "UPDATE PRICES",
}