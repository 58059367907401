import React, { Fragment, memo, useMemo } from "react";
import { Col, Form, Select } from "antd";
import * as texts from "../locales";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { useFreire } from "../../../utils/freireContext";
import { City, Province } from "../../../requests/country/types";
import { sorter } from "../../../utils/sorter";
import { useOnUpdate } from "../../../hooks";

const { Option } = Select;

interface IArgentinaSelectLocation {
  form: WrappedFormUtils<any>;
  disabled: boolean;
  required: boolean;
  loadingProvinces: boolean;
  loadingCities: boolean;
  provinces: Province[];
  cities: City[];
  onProvinceChange: (provinceCode: string) => void;
}

const ArgentinaSelectLocation: React.FC<IArgentinaSelectLocation> = ({
  form,
  disabled,
  required,
  loadingProvinces,
  loadingCities,
  provinces,
  cities,
  onProvinceChange,
}) => {
  const { freire } = useFreire();

  const requiredFieldRule = {
    required,
    message: freire(texts.REQUIRED_FIELD),
  };

  const selectedProvince = form.getFieldValue("address2.province");

  useOnUpdate(() => {
    if (selectedProvince) {
      onProvinceChange(selectedProvince);
    }
  }, [selectedProvince]);

  const onSelectProvince = () => form.resetFields(["address2.city"]);

  const provincesSorted: Province[] = useMemo(
    () => sorter(provinces, "nombre"),
    [provinces]
  );

  const citiesSorted: City[] = useMemo(
    () => sorter(cities, "nombre"),
    [cities]
  );

  return (
    <Fragment>
      <Col span={12}>
        <Form.Item label={freire(texts.PROVINCE)}>
          {form.getFieldDecorator("address2.province", {
            rules: [requiredFieldRule],
          })(
            <Select
              showSearch
              placeholder={
                loadingProvinces
                  ? freire(texts.LOADING)
                  : freire(texts.SELECT_PROVINCE)
              }
              onSelect={onSelectProvince}
              loading={loadingProvinces}
              disabled={loadingProvinces || disabled}
            >
              {provincesSorted.map((province) => (
                <Option
                  key={`${province.iso_id}-${province.nombre}`}
                  value={province.iso_id}
                >
                  {province.nombre}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={freire(texts.CITY)}>
          {form.getFieldDecorator("address2.city", {
            rules: [requiredFieldRule],
          })(
            <Select
              showSearch
              placeholder={
                loadingCities
                  ? freire(texts.LOADING)
                  : freire(texts.SELECT_CITY)
              }
              loading={loadingCities}
              disabled={!selectedProvince || loadingCities || disabled}
            >
              {citiesSorted.map((city) => (
                <Option key={city.id} value={city.nombre}>
                  {city.nombre}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export default memo(ArgentinaSelectLocation);
