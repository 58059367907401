import axios from "axios";
import {
  setLanguageInterceptor,
  setTokenRequestInterceptor,
} from "./interceptors";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  transitional: {
    clarifyTimeoutError: true,
  },
});

api.interceptors.request.use(setTokenRequestInterceptor);
api.interceptors.request.use(setLanguageInterceptor);

export default api;
