export const USER = {
  pt: "Usuário",
  es: "Usuario",
  en: "User",
};

export const ALL_RIGHT = {
  pt: "Tudo certo",
  es: "de acuerdo",
  en: "All set",
};

export const OPERATION_SUCCESSFULLY = {
  pt: "Operação realizada com sucesso!",
  es: "¡Operación realizada con éxito!",
  en: "Successfully performed operation!",
};

export const SOMETHING_WRONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const MAKE_USER_ADMIN = {
  pt: "Tornar usuário Admin",
  es: "Hacer al usuario administrador",
  en: "Make User Admin",
};

export const PORTUGUES = {
  pt: "Português",
  es: "Portugués",
  en: "Portuguese",
};

export const ENGLISH = {
  pt: "Inglês",
  es: "Inglés",
  en: "English",
};

export const SPANISH = {
  pt: "Espanhol",
  es: "Español",
  en: "Spanish",
};

export const EXIT = {
  pt: "Sair",
  es: "Salir",
  en: "Exit",
};

export const MENU_STATION = {
  pt: "Estações",
  es: "Estaciones",
  en: "Stations",
};

export const MENU_STATION_AVAILABILITY_24H = {
  pt: "Estações (24h)",
  es: "Estaciones (24h)",
  en: "Stations (24h)",
};

export const MENU_STATION_AVAILABILITY_REAL = {
  pt: "Estações (real)",
  es: "Estaciones (real)",
  en: "Stations (real)",
};

export const LISTING = {
  pt: "Listagem",
  es: "Lista",
  en: "Listing",
};

export const CONNECTED = {
  pt: "Conectadas",
  es: "Conectadas",
  en: "Connected",
};

export const AUDIT = {
  pt: "Auditoria",
  es: "Auditoría",
  en: "Audit",
};

export const VISIBILITY = {
  pt: "Visibilidade",
  es: "Visibilidad",
  en: "Visibility",
};

export const GROUPS = {
  pt: "Grupos",
  es: "Grupos",
  en: "Groups",
};

export const CREATE_GROUP = {
  pt: "Criar grupo",
  es: "Crear grupo",
  en: "Create group",
};

export const DASHBOARD = {
  pt: "Dashboard",
  es: "Tablero de mandos",
  en: "Dashboard",
};

export const USERS = {
  pt: "Usuários",
  es: "Usuarios",
  en: "Users",
};

export const ADVERTISING = {
  pt: "Publicidade",
  es: "Publicidad",
  en: "Advertising",
};

export const MANAGEMENT = {
  pt: "Administração",
  es: "Administración",
  en: "Administration",
};

export const COMPANIES = {
  pt: "Empresas",
  es: "Empresas",
  en: "Companies",
};

export const RECIPIENTS = {
  pt: "Recebedores",
  es: "Destinatarios",
  en: "Recipients",
};

export const PLUGS = {
  pt: "Plugs",
  es: "Enchufes",
  en: "Plugs",
};

export const POWER_BI = {
  pt: "Power BI",
  es: "Power BI",
  en: "Power BI",
};

export const PERMISSIONS = {
  pt: "Permissões",
  es: "Permisos",
  en: "Permissions",
};

export const REPORTS = {
  pt: "Relatórios",
  es: "Informes",
  en: "Reports",
};

export const HISTORIES = {
  pt: "Históricos",
  es: "Historiales",
  en: "Histories",
};

export const CHARGES = {
  pt: "Cargas",
  es: "Cargas",
  en: "Charges",
};

export const PAYMENTS = {
  pt: "Pagamentos",
  es: "Pagos",
  en: "Payments",
};

export const COUPONS = {
  pt: "Cupons",
  es: "Cupones",
  en: "Coupons",
};

export const RFID = {
  pt: "Tags RFID",
  es: "Etiquetas RFID",
  en: "RFID Tags",
};

export const AVAILABILITY = {
  pt: "Disponibilidade",
  es: "Disponibilidad",
  en: "Availability",
};

export const PROPOSAL = {
  pt: "Propostas",
  es: "Propuestas",
  en: "Proposals",
};

export const MENU_PROPOSAL_LIST = {
  pt: "Ver propostas",
  es: "Propuestas",
  en: "Proposals",
};

export const MENU_PROPOSAL_CREATION = {
  pt: "Criar propostas",
  es: "Propuestas",
  en: "Proposals",
};

export const SYSTEM = {
  pt: "Sistema",
  es: "Sistema",
  en: "System",
};

export const OCPP = {
  pt: "OCPP",
  es: "OCPP",
  en: "OCPP",
};

export const OPERATION = {
  pt: "Operações",
  es: "Operaciones",
  en: "Operations",
};

export const ALERTS = {
  pt: "Alertas",
  es: "Alertas",
  en: "Alerts",
};

export const TAGS = {
  pt: "Tags",
  es: "Etiquetas",
  en: "Tags",
};

export const LOGS = {
  pt: "Logs",
  es: "Registros",
  en: "Logs",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const E_MAIL = {
  pt: "E-mail",
  es: "Correo electrónico",
  en: "E-mail",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const COMPANY = {
  pt: "Empresa",
  es: "Empresa",
  en: "Company",
};

export const SELECT_COMPANY = {
  pt: "Selecione a empresa",
  es: "Seleccione la empresa",
  en: "Select the company",
};

export const ADMIN = {
  pt: "Admin",
  es: "Administración",
  en: "Admin",
};

export const YES = {
  pt: "Sim",
  es: "Sí",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const MENU_DOCS = {
  pt: "Docs",
};

export const LANGUAGE = {
  pt: "Idioma",
};

export const TEST_APP_BETA = {
  pt: "Testar versão beta",
  es: "Prueba la versión beta",
  en: "Test beta version",
}

export const BACK_APP_STABLE = {
  pt: "Voltar para versão estável",
  es: "Volver a la versión estáble",
  en: "Back to stable version",
}

export const OKR_POWER_BI = {
  pt: "OKR Power BI",
}

export const BATCH_OPERATION = {
  pt: "Operação Em Lote",
  es: "Operación en Lote",
  en: "Batch Operation",
}

export const MENU_LOCATION = {
  pt: "Localizações",
  es: "Localizaciones",
  en: "Locations",
}