import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
  message,
  Modal,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import { validate as isValidEmail } from "email-validator";
import api from "../../../services/api";
import * as requests from "../../../requests/index";
import "./index.css";
import { BRAND } from "../../../config";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import { sorter } from "../../../utils/sorter";
import SelectCountry from "../../../components/SelectCountry";
import CountryFlag from "../../../components/SelectCountry/CountryFlag";
import stationReportImg from "../../../assets/img/stationReport.png";
import MonthlyCouponReport from "../../../assets/img/monthlyCouponReport.png";
import quotaReportImg from "../../../assets/img/quota-consumption-alert.png";
import SelectWithNewItem from "../../../components/SelectWithNewItem";
import FinancialForm from "../FinancialForm";

const { Option } = Select;
class ListCompanies extends Component {
  static contextType = FreireContext;

  state = {
    loadingTable: false,
    loadingFetchData: false,
    hasIconPack: false,
    iconPacks: [],
    whiteLabels: [],
    countries: [],
    data: [],
    visible: false,
    company: {},
    admin: false,
    companys: [],
    recipients: [],
    recipient: {},
    dateFiltrada: [],
    tablePage: 1,
    emailSwitch: false,
    emails: [],
    inputEmail: "",
    showModal1: false,
    showModal2: false,
    showModal3: false,
    setEmails: [],
    loadingSave: false,
    setLoadingSave: false,
    customerTypes: [],
    plans: [],
  };
  showModal1 = () => {
    this.setState({ showModal1: true });
  };

  handleCancel1 = () => {
    this.setState({ showModal1: false });
  };

  showModal2 = () => {
    this.setState({ showModal2: true });
  };

  handleCancel2 = () => {
    this.setState({ showModal2: false });
  };

  showModal3 = () => {
    this.setState({ showModal3: true });
  };

  handleCancel3 = () => {
    this.setState({ showModal3: false });
  };

  componentDidMount() {
    this.getCompanys();
    this.fetchData();
  }

  fetchData = async () => {
    const { freire } = this.context;

    try {
      this.setState({ loadingFetchData: true });

      const [iconPacks, whiteLabels, countries, customerTypes, plans] =
        await Promise.all([
          this.getIconPacks(),
          this.getWhiteLabels(),
          this.getCountries(),
          this.getCustomerTypes(),
          this.getContractedPlans(),
        ]);

      this.setState({
        iconPacks,
        whiteLabels,
        countries,
        customerTypes,
        plans,
      });
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: error.message,
      });
    } finally {
      this.setState({ loadingFetchData: false });
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  save() {
    this.props.form.validateFields(async (err, values) => {
      if (err) return;

      values.tupiRate = (values?.tupiRate ?? 0) / 100;
      values.monthlyFee = Number(values.monthlyFee?.replace(/[.,]/g, ""));

      const payload = { ...values };

      this.putCompany(payload);
    });
  }

  getContractedPlans = async () => {
    const { freire } = this.context;

    try {
      const { data: plans } = await api.get("plans");
      return plans;
    } catch (error) {
      throw new Error(freire(texts.ERROR_CONTRACTED_PLAN));
    }
  };

  getCustomerTypes = async () => {
    const { freire } = this.context;

    try {
      const {
        data: { customerTypes },
      } = await api.get("customer-types");
      return customerTypes;
    } catch (error) {
      throw new Error(freire(texts.ERROR_CUSTOMER_TYPE));
    }
  };

  onAddNewCustomerType = async (newCustomerType) => {
    this.setState((state) => ({
      customerTypes: [...state.customerTypes, { name: newCustomerType }],
      company: { ...state.company, customerType: newCustomerType },
    }));
  };

  getCompanys = async (params = {}) => {
    const { freire } = this.context;

    try {
      this.setState({ loadingTable: true });

      const { data: companiesData } = await api.get("company");
      const companies = sorter(companiesData, "companyName");

      const { data: recipients } = await api.get("recipient");

      this.setState({
        loadingTable: false,
        data: companies,
        dateFiltrada: companies,
        recipients,
      });
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_COMPANIES),
      });
    } finally {
      this.setState({ loadingTable: false });
    }
  };

  getIconPacks = async () => {
    const { freire } = this.context;

    try {
      const { data: iconPacks } = await api.get("stations/icon-packs");

      return iconPacks;
    } catch (error) {
      throw new Error(freire(texts.ERROR_LOAD_PACK_ICONS));
    }
  };

  getWhiteLabels = async () => {
    const { freire } = this.context;

    try {
      const { data: whiteLabels } = await api.get("/white-label");

      return whiteLabels;
    } catch (error) {
      throw new Error(freire(texts.ERROR_LOAD_WHITELABEL_LIST));
    }
  };

  getCountries = async () => {
    const { freire } = this.context;

    try {
      const countries = await requests.getRecommendedCountries();
      return countries;
    } catch (error) {
      throw new Error(freire(texts.ERROR_LIST_COUNTRIES));
    }
  };

  openDrawer = async (company) => {
    this.setState({
      visible: true,
      company,
      hasIconPack: company.iconPack !== undefined,
      emails: company.emails ?? [],
    });
  };

  closeDrawer = () => {
    this.setState({ visible: false });
  };

  filter = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      const { companyName, country } = values;

      const filteredCompanies = this.state.data.filter((company) => {
        const nameMatches =
          !companyName ||
          company.companyName.toLowerCase().includes(companyName.toLowerCase());

        const countryMatches = !country || company.country === country;

        return nameMatches && countryMatches;
      });

      const sortedCompanies = sorter(filteredCompanies, "companyName");

      this.setState({
        dateFiltrada: sortedCompanies,
        tablePage: 1,
      });
    });
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.setState({ dateFiltrada: sorter(this.state.data, "companyName") });
    this.getCompanys();
  };

  goNew = () => {
    this.props.history.push("/dashboard/new_company");
  };

  onSearch(val) {
    // console.log("search:", val);
  }

  onSelect = (val) => {
    var recipients = this.state.recipients;
    var recipient = recipients.find((element) => val === element._id);
    this.setState({ recipient: recipient });
  };

  addRecebedor = () => {
    var { company } = this.state;
    var recipient = this.state.recipient;
    recipient.percentage = 0;
    company.splitRule.push(recipient);
    this.setState({ company });
  };

  putCompany = async (extraInfo = null) => {
    const { freire } = this.context;
    const { company } = this.state;

    try {
      if (company.hasSplit) {
        if (
          this.state.company.splitRule.reduce(
            (total, rule) => parseInt(total) + parseInt(rule.percentage),
            0
          ) === 100
        ) {
        } else {
          alert(freire(texts.TOTAL_NOT_FULL));
          return;
        }
      }
      await api.put(`company`, {
        ...(extraInfo || {}),
        _id: company._id,
        parentCompanyID: company.parentCompanyID,
        document_type: company.document_type,
        document: company.document,
        companyName: company.companyName,
        pipedrive_id: company.pipedrive_id,
        hasSplit: company.hasSplit,
        splitRule: company.splitRule,
        iconPack: company.iconPack ?? null,
        whiteLabelAlias: company.whiteLabelAlias ?? null,
        powerbi: company.powerbi,
        emails: this.state.emails,
        customerType: company.customerType ?? null,
      });
      this.getCompanys();
      this.closeDrawer();
    } catch (err) {}
  };
  getColumnsSplit = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.RECIPIENT),
        dataIndex: "recipientName",
        key: "recipientName",
      },
      {
        title: freire(texts.PERCENTAGE),
        dataIndex: "percentage",
        key: "percentage",
        render: (value, row, index) => {
          return (
            <Input
              type="number"
              value={
                this.state.company
                  ? this.state.company.splitRule[index].percentage
                  : ""
              }
              maxLength={2}
              onChange={(e) => {
                var company = this.state.company;
                if (
                  this.state.company.splitRule.reduce(
                    (total, rule) =>
                      parseInt(total) + parseInt(rule.percentage),
                    0
                  ) -
                    parseInt(this.state.company.splitRule[index].percentage) +
                    parseInt(e.target.value) <=
                    100 &&
                  e.target.value > 0
                ) {
                  company.splitRule[index].percentage = e.target.value;
                  this.setState({ company });
                }
              }}
            />
          );
        },
      },
      {
        title: freire(texts.LIABLE),
        dataIndex: "liable",
        key: "liable",
        render: (value, row, index) => {
          return (
            <Switch
              value={
                this.state.company
                  ? this.state.company.splitRule[index].liable
                  : false
              }
              onChange={(e) => {
                var company = this.state.company;
                company.splitRule[index].liable = e;
                this.setState({ company });
              }}
            />
          );
        },
      },
      {
        title: freire(texts.CHARGE_PROCESSING_FEE),
        dataIndex: "charge_processing_fee",
        key: "charge_processing_fee",
        render: (value, row, index) => {
          return (
            <Switch
              value={
                this.state.company
                  ? this.state.company.splitRule[index].charge_processing_fee
                  : false
              }
              onChange={(e) => {
                var company = this.state.company;
                company.splitRule[index].charge_processing_fee = e;
                this.setState({ company });
              }}
            />
          );
        },
      },
      {
        title: freire(texts.DELETE),
        key: "delet",
        render: (value, row, index) => {
          return (
            <Button
              onClick={() => {
                var company = this.state.company;
                company.splitRule.splice(index, 1);
                this.setState({ company });
              }}
            />
          );
        },
      },
    ];
  };

  getColumnsTable = () => {
    const { freire } = this.context;

    const renderCountry = (countryCode) => {
      const country = this.state.countries.find(
        (country) => country.code === countryCode
      );

      return country ? <CountryFlag country={country} /> : "----";
    };

    const renderParentCompany = (parentCompanyID) => {
      return (
        this.state.data.find((company) => company._id === parentCompanyID)
          ?.companyName ?? "----"
      );
    };

    return [
      {
        title: freire(texts.NAME),
        dataIndex: "companyName",
        key: "companyName",
      },
      {
        title: freire(texts.COUNTRY),
        dataIndex: "country",
        key: "country",
        render: renderCountry,
      },
      {
        title: freire(texts.PARENT_COMPANY),
        dataIndex: "parentCompanyID",
        key: "parentCompanyID",
        render: renderParentCompany,
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: "10%",
        render: (text, data) => (
          <Show when={"edit-company"}>
            <Tooltip placement="top" title={freire(texts.SHOW_DETAILS_EDIT)}>
              <Button
                shape="circle"
                size="small"
                ghost
                type="primary"
                style={{ marginRight: 8 }}
                onClick={() => this.openDrawer(data)}
              >
                <Icon type="edit" />
              </Button>
            </Tooltip>
          </Show>
        ),
      },
    ];
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      hasIconPack,
      loadingFetchData,
      iconPacks,
      whiteLabels,
      countries,
      customerTypes,
      plans,
      company,
    } = this.state;
    const { loading } = this.props;
    const { freire } = this.context;
    const columns = this.getColumnsTable();
    const columnsSplit = this.getColumnsSplit();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.MANAGEMENT),
            freire(texts.COMPANIES),
          ]}
        />
        <div className="filter">
          <Form wrapperCol={{ span: 24 }}>
            <Form.Item>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {getFieldDecorator("companyName")(
                  <Input
                    className="firstInput"
                    placeholder={freire(texts.SEARCH_BY_COMPANY)}
                    onKeyPress={this.handleKeyPress}
                    onChange={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                  />
                )}
                <Button
                  icon="search"
                  className="buttonSearch"
                  onClick={this.filter}
                >
                  {freire(texts.SEARCH)}
                </Button>
                <Show when={"add-company"}>
                  <Button
                    icon="plus"
                    className="buttonNew"
                    onClick={this.goNew}
                    type="primary"
                  >
                    {freire(texts.NEW)}
                  </Button>
                </Show>
              </div>
            </Form.Item>
            <SelectCountry
              form={this.props.form}
              isRequired={false}
              disabled={loadingFetchData}
              loading={loadingFetchData}
              countries={countries}
              showWarn={false}
            />
          </Form>

          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAN)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          loading={this.state.loadingTable}
          rowKey={(data) => data._id}
          dataSource={this.state.dateFiltrada}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />

        <Drawer
          title={freire(texts.EDIT_COMPANY)}
          width={700}
          onClose={this.closeDrawer}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <span className="titleNewCompanyEdt">{freire(texts.INFO)}</span>
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label={freire(texts.NAME)}>
                  <Input
                    placeholder={freire(texts.NAME)}
                    value={this.state.company.companyName}
                    onChange={(value) => {
                      let company = this.state.company;
                      company.companyName = value.target.value;
                      this.setState({ company });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <Form.Item label={freire(texts.SELECT_DOCUMENT_TYPE)}>
                  <Select
                    placeholder={freire(texts.SELECT_DOCUMENT_TYPE)}
                    value={this.state.company.document_type}
                    onChange={(value) => {
                      let company = this.state.company;
                      company.document_type = value;
                      this.setState({ company });
                    }}
                  >
                    {["CNPJ", "CUIT"].map((value) => (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label={freire(texts.COMPANY_DOCUMENT)}>
                  <Input
                    value={this.state.company.document}
                    onChange={(value) => {
                      let company = this.state.company;
                      company.document = value.target.value;
                      this.setState({ company });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item label={freire(texts.PIPEDRIVE_ID)}>
                  <Input
                    value={this.state.company.pipedrive_id}
                    onChange={(value) => {
                      let company = this.state.company;
                      company.pipedrive_id = value.target.value;
                      this.setState({ company });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {this.state.company._id !== BRAND && (
              <Show when={"edit-parent-company"}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label={freire(texts.PARENT_COMPANY)}>
                      <Select
                        disabled={this.state.loadingSave}
                        placeholder={freire(texts.SELECT_PARENT_COMPANY)}
                        showSearch
                        optionFilterProp="children"
                        value={this.state.company.parentCompanyID}
                        onChange={(parentCompanyID) => {
                          this.setState((state) => ({
                            company: { ...state.company, parentCompanyID },
                          }));
                        }}
                      >
                        {this.state.data
                          .filter(
                            (company) =>
                              company.holding &&
                              company._id !== this.state.company._id
                          )
                          .map((company) => (
                            <Option key={company._id} value={company._id}>
                              {company.companyName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Show>
            )}
            <Row gutter={[16, 0]}>
              <Col span={10}>
                <SelectCountry
                  disabled={true}
                  initialValue={this.state.company?.country ?? "----"}
                  loading={loadingFetchData}
                  countries={countries}
                  showWarn={false}
                />
              </Col>
              <Col span={14}>
                <Form.Item label={freire(texts.TYPE_OF_COSTUMER)}>
                  <SelectWithNewItem
                    value={this.state.company?.customerType ?? undefined}
                    onChange={(customerType) => {
                      this.setState((state) => ({
                        company: { ...state.company, customerType },
                      }));
                    }}
                    onAddNewItem={this.onAddNewCustomerType}
                    placeholder={freire(texts.PLACEHOLDER_TYPE_OF_COSTUMER)}
                  >
                    {customerTypes.map((customerType) => (
                      <Option key={customerType.name} value={customerType.name}>
                        {customerType.name}
                      </Option>
                    ))}
                  </SelectWithNewItem>
                </Form.Item>
              </Col>
            </Row>
            <Show when={"edit-company-pin"}>
              <span className="titleNewCompanyEdt">
                {freire(texts.CUSTOMIZATION)}
              </span>
              <Row gutter={[16, 0]}>
                <Col span={8}>
                  <Form.Item label={freire(texts.PINS_CUSTOMIZED)}>
                    <Switch
                      checkedChildren={freire(texts.YES)}
                      unCheckedChildren={freire(texts.NO)}
                      onChange={(hasIconPack) => {
                        this.setState((state) => {
                          const updatedCompany = hasIconPack
                            ? { ...state.company }
                            : { ...state.company, iconPack: undefined };
                          return { hasIconPack, company: updatedCompany };
                        });
                      }}
                      checked={hasIconPack}
                      disabled={loadingFetchData || iconPacks.length === 0}
                    />
                    {hasIconPack && (
                      <Form.Item style={{ marginTop: 8 }}>
                        <Select
                          placeholder={freire(texts.CHOOSE_PIN)}
                          value={this.state.company.iconPack}
                          onChange={(value) =>
                            this.setState((state) => ({
                              company: { ...state.company, iconPack: value },
                            }))
                          }
                        >
                          {iconPacks.map((iconPack) => (
                            <Select.Option
                              key={iconPack.alias}
                              value={iconPack.alias}
                            >
                              {iconPack.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>

                <Col span={(16, 0)}>
                  <Form.Item label={freire(texts.CHOOSE_PIN)}>
                    {hasIconPack ? (
                      <Select
                        allowClear
                        loading={loadingFetchData}
                        disabled={loadingFetchData || iconPacks.length === 0}
                        placeholder={freire(texts.PATTERN)}
                        showSearch
                        value={this.state.company.iconPack}
                        onChange={(iconPack) => {
                          this.setState((state) => ({
                            company: { ...state.company, iconPack },
                          }));
                        }}
                      >
                        {iconPacks.map((iconPack) => (
                          <Select.Option
                            key={iconPack.alias}
                            value={iconPack.alias}
                          >
                            {iconPack.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        loading={loadingFetchData}
                        disabled
                        placeholder={freire(texts.PATTERN)}
                        value={undefined}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={freire(texts.URL_POWERBI)}>
                    <Input
                      placeholder={freire(texts.URL_POWERBI)}
                      value={this.state.company.powerbi}
                      onChange={(value) => {
                        let company = this.state.company;
                        company.powerbi = value.target.value;
                        this.setState({ company });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Show>
            <Show when={"edit-company-white-label"}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={freire(texts.WHITE_LABEL)}>
                    <Select
                      allowClear
                      showSearch
                      loading={loadingFetchData}
                      disabled={loadingFetchData || whiteLabels.length === 0}
                      placeholder={freire(texts.SELECT_WHITELABEL)}
                      optionFilterProp="children"
                      value={this.state.company?.whiteLabelAlias ?? undefined}
                      onChange={(whiteLabelAlias) => {
                        this.setState((state) => ({
                          company: { ...state.company, whiteLabelAlias },
                        }));
                      }}
                    >
                      {whiteLabels.map(({ alias, name }) => (
                        <Select.Option key={alias} value={alias}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Show>
            <Show when={"edit-company-split"}>
              <Row>
                <Col span={12}>
                  <Form.Item label={freire(texts.PAYMENT_SPLIT_RULE)}>
                    <Switch
                      checkedChildren={freire(texts.YES)}
                      unCheckedChildren={freire(texts.NO)}
                      onChange={(value) => {
                        let company = this.state.company;
                        company.hasSplit = value;

                        this.setState({ company });
                      }}
                      checked={this.state.company.hasSplit}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.state.company.hasSplit ? (
                    <>
                      <Table
                        dataSource={this.state.company.splitRule}
                        columns={columnsSplit}
                        pagination={false}
                      />
                      <Row>
                        <Button
                          style={{ marginRight: 8 }}
                          onClick={() => this.putCompany()}
                        >
                          {freire(texts.SAVE)}
                        </Button>
                        <Button style={{ marginRight: 8 }}>
                          {freire(texts.TOTAL)}{" "}
                          {this.state.company.splitRule.length > 0
                            ? this.state.company.splitRule.reduce(
                                (total, rule) =>
                                  parseInt(total) + parseInt(rule.percentage),
                                0
                              )
                            : 0}{" "}
                          %
                        </Button>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder={freire(texts.SELECT_RECIPIENT)}
                          optionFilterProp="children"
                          onChange={this.onSelect}
                          onSearch={this.onSearch}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.recipients.map((item) => {
                            return (
                              <Option value={item._id} key={item.recipient_id}>
                                {item.recipientName}
                              </Option>
                            );
                          })}
                        </Select>
                        <Button
                          style={{ marginRight: 8 }}
                          onClick={() => this.addRecebedor()}
                        >
                          {freire(texts.ADD_MORE)}
                        </Button>
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Show>

            <FinancialForm
              form={this.props.form}
              plans={plans}
              country={company?.country}
              visibleDrawer={this.state.visible}
              fieldsToEdit={{
                contractedPlan: company?.contractedPlan,
                monthlyFee: company?.monthlyFee,
                numberChargers: company?.numberChargers,
                taxModel: company?.taxModel,
                tupiRate: company?.tupiRate,
              }}
            />
          </Form>
          <Row gutter={[16, 0]} style={{ marginTop: "30px" }}>
            <Col span={24}>
              <Form wrapperCol={{ span: 24 }}>
                <p className="titleNewCompanyEdt">{freire(texts.REPORT)}</p>
                <Form.Item label={freire(texts.EMAIL_LIST)}>
                  <p className="titleForSelectModalEdit">
                    Os e-mails nessa lista receberão relatórios mensais com
                    atualizações das estações
                    <span
                      role="button"
                      tabIndex={0}
                      style={{ cursor: "pointer", margin: "3px" }}
                      onClick={this.showModal1}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          this.showModal1();
                        }
                      }}
                      onTouchStart={(e) => {
                        e.preventDefault();
                        this.showModal1();
                      }}
                      aria-label="Exemplo de relatório de estações"
                    >
                      <strong>(exemplo)</strong>
                    </span>
                    <Modal
                      visible={this.state.showModal1}
                      onCancel={this.handleCancel1}
                      footer={null}
                      maskClosable={true}
                    >
                      <img src={stationReportImg} alt="Station Report" />
                    </Modal>
                    - consumo de kWh, número de usuários, quantidade e tempo
                    médio de cargas - e a utilização dos cupons
                    <span
                      role="button"
                      tabIndex={0}
                      style={{ cursor: "pointer", margin: "3px" }}
                      onClick={this.showModal2}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          this.showModal2();
                        }
                      }}
                      onTouchStart={(e) => {
                        e.preventDefault();
                        this.showModal2();
                      }}
                      aria-label="Exemplo de relatório mensal de cupons"
                    >
                      <strong>(exemplo)</strong>
                    </span>
                    <Modal
                      visible={this.state.showModal2}
                      onCancel={this.handleCancel2}
                      footer={null}
                      maskClosable={true}
                    >
                      <img src={MonthlyCouponReport} alt="Monthly Coupon" />
                    </Modal>
                    incluindo alertas de consumo da cota
                    <span
                      role="button"
                      tabIndex={0}
                      style={{ cursor: "pointer", margin: "3px" }}
                      onClick={this.showModal3}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          this.showModal3();
                        }
                      }}
                      onTouchStart={(e) => {
                        e.preventDefault();
                        this.showModal3();
                      }}
                      aria-label="Exemplo de relatório de consumo da cota"
                    >
                      <strong>(exemplo)</strong>
                    </span>
                    <Modal
                      visible={this.state.showModal3}
                      onCancel={this.handleCancel3}
                      footer={null}
                      maskClosable={true}
                    >
                      <img src={quotaReportImg} alt="Quota Report" />
                    </Modal>
                  </p>

                  {getFieldDecorator("emails", {
                    initialValue: this.state.emails,
                  })(
                    <Select
                      mode="tags"
                      disabled={loading}
                      style={{ width: "100%" }}
                      placeholder={freire(texts.INPUT_EMAILS)}
                      onSelect={(value) => {
                        if (!isValidEmail(value)) {
                          message.error(freire(texts.INFORM_VALID_EMAIL));
                        } else {
                          this.setState((prevState) => ({
                            emails: [...prevState.emails, value],
                          }));
                        }
                      }}
                      onDeselect={(value) => {
                        this.setState((prevState) => ({
                          emails: prevState.emails.filter(
                            (email) => email !== value
                          ),
                        }));
                      }}
                    />
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
              {freire(texts.CANCEL)}
            </Button>
            <Button
              loading={this.state.loadingSave}
              onClick={() => {
                this.save();
              }}
              type="primary"
            >
              {freire(texts.SAVE)}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}
const PageListCompanies = Form.create({
  name: "list_companies_form",
  mapPropsToFields(props) {
    return {
      loading: Form.createFormField({
        value: props.loading,
      }),
    };
  },
})(ListCompanies);
export default withRouter(PageListCompanies);
