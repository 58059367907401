import React, { Component, Fragment } from "react";
import {
  Button,
  Input,
  InputNumber,
  Select,
  Form,
  Col,
  Row,
  Switch,
  Icon,
  Steps,
  notification,
  Radio,
  Divider,
  DatePicker,
} from "antd";
import { withRouter } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import moment from "moment-timezone";
import { Show } from "../../../contexts/PermissionContext";
import * as texts from "../locales";
import { FreireContext } from "../../../utils/freireContext";
import { sorter } from "../../../utils/sorter";
import SelectCountry from "../../../components/SelectCountry";
import SelectLocation from "../../../components/SelectLocation";
import { COUNTRY_BR } from "../../../constants/countries";
import { POWER_AC, POWER_DC } from "../../../constants/power";
import { sleep } from "../../../utils/sleep";

const { Step } = Steps;
const { Option } = Select;

class NewStations extends Component {
  static contextType = FreireContext;

  state = {
    loadingSave: false,
    isConnected: false,
    companies: [],
    locations: [],
    plugs: [],
    listLocals: [],
    listModels: [],
    listTypes: [],
    plugsSelecteds: [],
    connectedPlugs: [],
    isPlugsConcurrently: false,
  };

  componentDidMount = async () => {
    const { data: companies } = await api.get("company");

    const companiesNotHolding = sorter(
      companies.filter((company) => !company.holding),
      "companyName"
    );

    const { data: plugs } = await api.get(`plugs`);
    this.getLocations();
    this.getStationLocals();
    this.getStationModels();
    this.getStationTypes();
    this.setState({ companies: companiesNotHolding, plugs });
  };

  async getStationLocals() {
    const { freire } = this.context;

    try {
      const response = await api.get(`station-locals`);
      this.setState({ listLocals: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_LOCATIONS),
      });
    }
  }

  async getStationModels() {
    const { freire } = this.context;

    try {
      const response = await api.get(`station-models`);
      this.setState({ listModels: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_MODELS),
      });
    }
  }

  async getStationTypes() {
    const { freire } = this.context;

    try {
      const response = await api.get(`station-types`);
      this.setState({ listTypes: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_TYPES),
      });
    }
  }

  async getLocations() {
    const { freire } = this.context;
    try {
      const response = await api.get(`locations`);
      this.setState({ locations: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_LOCATIONS),
      });
    }
  }

  salvar = () => {
    const { freire } = this.context;

    this.props.form.validateFieldsAndScroll(
      { scroll: { offsetTop: 50 } },
      async (err, values) => {
        if (err) {
          return;
        }

        const location = this.state.locations.find(
          (location) => location.id === values.location_id
        );

        if (this.state.isConnected && !location.company_id) {
          notification.error({
            message: "Ops...",
            description: freire(
              texts.ERROR_LOCATION_WITHOUT_COMPANY_IN_CONNECTED_STATION
            ),
          });

          return;
        }

        this.setState({ loadingSave: true }, async () => {
          try {
            values.connectedPlugs = this.state.connectedPlugs;
            await api.post("stations", values);

            this.setState({ loadingSave: false });

            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: freire(texts.STATION_ADDED),
            });

            if (values.stationID) {
              this.props.history.push("/dashboard/connected_stations");
            } else {
              this.props.history.push("/dashboard/list_stations");
            }
          } catch (error) {
            this.setState({ loadingSave: false });
            const {
              response: {
                data: { message },
              },
            } = error;

            notification.error({
              message: freire(texts.WENT_WRONG),
              description: message || error.message,
            });
          }
        });
      }
    );
  };

  addPlug() {
    var { connectedPlugs } = this.state;
    var newConnectedPlug = {
      plug_id: null,
      current: null,
      power: null,
    };
    var newConnectedPlugs = connectedPlugs.concat(newConnectedPlug);

    this.setState({ connectedPlugs: newConnectedPlugs });
  }

  addPlugfArray() {
    var { plugsSelecteds } = this.state;
    const current = this.props.form.getFieldValue("current");
    const power = this.props.form.getFieldValue("power");
    if (plugsSelecteds.length > 0) {
      let plugs = plugsSelecteds.map((plug) => {
        return {
          plug_id: plug,
          current: current || null,
          power: power || null,
        };
      });
      this.setState({ connectedPlugs: plugs });
    }
  }

  changerPlug(plug, index) {
    var { connectedPlugs } = this.state;
    connectedPlugs[index].plug_id = plug;

    this.setState({ connectedPlugs });
  }

  handlePlugs = (value, e) => {
    this.setState({
      plugsSelecteds: value,
    });
  };

  //plugs = ["Tipo 2", "CCS 2", "CHAdeMO", "Tipo 1", "GB/T", "Tesla"];

  onSelectCountry = () => {
    this.props.form.resetFields(["address2.city", "companyID"]);
  };

  onChangeLocation(id) {
    const location = this.state.locations.find(
      (location) => location.id === id
    );

    this.props.form.setFieldsValue({
      name: location.name,
      country: location.country,
      lat: location.coordinates.latitude,
      lng: location.coordinates.longitude,
      address: location.address,
      phone: location.phone,
      private: location.private,
      freeParking: location.freeParking,
    });

    if (location.country === COUNTRY_BR) {
      this.props.form.setFieldsValue({
        address2: {
          state: location.state,
          city: location.city,
        },
      });
    } else {
      this.props.form.setFieldsValue({
        address2: {
          province: location.state,
          city: location.city,
        },
      });
    }

    if (this.state.isConnected) {
      this.props.form.setFieldsValue({
        companyID: location.company_id,
      });
    }
  }

  async onChangeIsConnected(value) {
    this.setState({ isConnected: value });

    if (value) {
      this.addPlugfArray();

      const location_id = this.props.form.getFieldValue("location_id");
      const location = this.state.locations.find(
        (location) => location.id === location_id
      );

      if (location) {
        await sleep(300);
        this.props.form.setFieldsValue({
          companyID: location.company_id,
        });
      }
    }
  }

  render() {
    const {
      isConnected,
      companies,
      loadingSave,
      plugs,
      plugsSelecteds,
      connectedPlugs,
      listLocals,
      listModels,
      listTypes,
      locations,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const current = this.props.form.getFieldValue("current");
    const { freire } = this.context;

    const selectedCountry = this.props.form.getFieldValue("country");

    const userHasOnlyOneCompany = companies.length === 1;
    const initialCountry = userHasOnlyOneCompany
      ? companies[0].country
      : COUNTRY_BR;

    const requiredFieldRule = {
      required: true,
      message: freire(texts.REQUIRED_FIELD),
    };

    const formList =
      connectedPlugs &&
      connectedPlugs.map((item, index) => (
        <div key={index} style={{ width: "90%", marginLeft: "5%" }}>
          <span
            style={{
              color: "#7A84BF",
              fontWeight: "bold",
              marginBottom: 8,
            }}
          >
            {freire(texts.POWER_PLUG(index + 1))}
          </span>
          <Row gutter={16}>
            <Col span={12} style={{ height: 75 }}>
              <div
                style={{
                  color: "rgb(0,0,0)",
                  fontSize: 14,
                  fontWeight: "bold",
                  paddingBottom: 8,
                }}
              >
                {freire(texts.PLUG)}
              </div>
              <Select
                defaultValue={item.plug_id}
                onChange={(e) => this.changerPlug(e, index)}
              >
                {plugsSelecteds &&
                  plugs
                    .filter((item) => plugsSelecteds.includes(item._id))
                    .map((plug) => (
                      <Option key={plug._id} value={plug._id}>
                        {plug.name}
                      </Option>
                    ))}
              </Select>
            </Col>
            <Col span={6} style={{ height: 75 }}>
              <div
                style={{
                  color: "rgb(0,0,0)",
                  fontSize: 14,
                  fontWeight: "bold",
                  paddingBottom: 8,
                }}
              >
                {freire(texts.CURRENT)}
              </div>
              <Radio.Group
                defaultValue={connectedPlugs[index].current}
                onChange={(e) => {
                  connectedPlugs[index].current = e.target.value;
                  connectedPlugs[index].power = null;
                  this.setState({ connectedPlugs });
                }}
                buttonStyle="solid"
              >
                <Radio.Button value="AC">AC</Radio.Button>
                <Radio.Button value="DC">DC</Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={6} style={{ height: 75 }}>
              <div
                style={{
                  color: "rgb(0,0,0)",
                  fontSize: 14,
                  fontWeight: "bold",
                  paddingBottom: 8,
                }}
              >
                {connectedPlugs[index].current
                  ? freire(texts.POWER(connectedPlugs[index].current))
                  : freire(texts.POWER(""))}
              </div>
              <Select
                disabled={
                  connectedPlugs[index] && !connectedPlugs[index].current
                }
                placeholder={freire(texts.SELECT_POWER)}
                defaultValue={connectedPlugs[index].power}
                onChange={(e) => {
                  connectedPlugs[index].power = e;
                  this.setState({ connectedPlugs });
                }}
              >
                {(connectedPlugs[index] &&
                connectedPlugs[index].current === "AC"
                  ? POWER_AC
                  : POWER_DC
                ).map((power) => (
                  <Option key={power} value={power}>
                    {`${power.toLocaleString(freire.userLanguage)} kW`}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Divider
            style={{
              marginTop: 0,
            }}
          />
        </div>
      ));

    return (
      <div className="container">
        <div className="contentFull">
          <Row>
            <Col span={4}>
              <Steps
                current={1}
                size="small"
                onChange={this.onChange}
                direction="vertical"
              >
                <Step
                  icon={<Icon type="profile" />}
                  title={freire(texts.STATION)}
                  description={freire(texts.ADD_STATION)}
                />
              </Steps>
            </Col>

            <Col span={20}>
              <Form wrapperCol={{ span: 24 }}>
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <Form.Item label={freire(texts.LOCALIZATION)}>
                      {getFieldDecorator("location_id", {
                        rules: [requiredFieldRule],
                      })(
                        <Select
                          showSearch
                          optionFilterProp="label"
                          onChange={(id) => this.onChangeLocation(id)}
                        >
                          {locations &&
                            sorter(locations).map((location) => (
                              <Option
                                key={location.id}
                                value={location.id}
                                label={location.name}
                              >
                                {location.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <Form.Item label={freire(texts.NAME_STATION)}>
                      {getFieldDecorator("name", {
                        rules: [requiredFieldRule],
                      })(<Input placeholder={freire(texts.NAME)} />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <SelectCountry
                      form={this.props.form}
                      label={texts.SELECT_COUNTRY}
                      disabled={true}
                      initialValue={initialCountry}
                      showWarn={false}
                      onSelect={this.onSelectCountry}
                    />
                  </Col>
                  <Col span={6}>
                    <Form.Item label={freire(texts.LATITUDE)}>
                      {getFieldDecorator("lat", { rules: [requiredFieldRule] })(
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder={freire(texts.LATITUDE_EX)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={freire(texts.LONGITUDE)}>
                      {getFieldDecorator("lng", { rules: [requiredFieldRule] })(
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder={freire(texts.LATITUDE_EX)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label={freire(texts.ADDRESS)}>
                      {getFieldDecorator("address")(
                        <Input
                          disabled={true}
                          placeholder={freire(texts.ADDRESS)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <SelectLocation
                    disabled={true}
                    form={this.props.form}
                    countryCode={selectedCountry}
                  />
                </Row>

                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.PHONE)}>
                      {getFieldDecorator("phone")(
                        <Input
                          disabled={true}
                          placeholder={freire(texts.PHONE)}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  {isConnected ? (
                    <Col span={12}>
                      <Form.Item label={freire(texts.COMPANY)}>
                        {getFieldDecorator("companyID")(
                          <Select
                            placeholder={freire(texts.SELECT_COMPANY)}
                            showSearch
                            disabled
                            optionFilterProp="children"
                          >
                            {companies.map((company) => (
                              <Option
                                disabled={company.country !== selectedCountry}
                                key={company._id}
                                value={company._id}
                              >
                                {company.companyName}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  ): null}
                </Row>

                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.PLUGS)}>
                      {getFieldDecorator("plugs")(
                        <Select mode="multiple" onChange={this.handlePlugs}>
                          {plugs &&
                            sorter(plugs).map((plug) => (
                              <Option key={plug._id} value={plug._id}>
                                {plug.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={freire(texts.CURRENT)}>
                      {getFieldDecorator("current", { initialValue: "AC" })(
                        <Radio.Group
                          onChange={() =>
                            this.props.form.resetFields(["power"])
                          }
                          buttonStyle="solid"
                        >
                          <Radio.Button value="AC">AC</Radio.Button>
                          <Radio.Button value="DC">DC</Radio.Button>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={freire(texts.POWER(current))}>
                      {getFieldDecorator("power")(
                        <Select placeholder={freire(texts.SELECT_POWER)}>
                          {(current === "AC" ? POWER_AC : POWER_DC).map(
                            (power) => (
                              <Option key={power} value={power}>
                                {`${power.toLocaleString(
                                  freire.userLanguage
                                )} kW`}
                              </Option>
                            )
                          )}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.PRIVATE_STATION_QUESTION)}>
                      {getFieldDecorator("private", {
                        valuePropName: "checked",
                      })(
                        <Switch
                          disabled={true}
                          checkedChildren={freire(texts.YES)}
                          unCheckedChildren={freire(texts.NO)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={freire(texts.FREE_PARKING_QUESTION)}>
                      {getFieldDecorator("freeParking", {
                        valuePropName: "checked",
                      })(
                        <Switch
                          disabled={true}
                          checkedChildren={freire(texts.YES)}
                          unCheckedChildren={freire(texts.NO)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={freire(texts.USE_PLUGS_CONURRENTLY)}>
                      {getFieldDecorator("plugsConcurrently", {
                        valuePropName: "checked",
                        onChange: (value) => {
                          this.setState({ isPlugsConcurrently: value });
                        },
                      })(
                        <Switch
                          checkedChildren={freire(texts.YES)}
                          unCheckedChildren={freire(texts.NO)}
                        />
                      )}
                    </Form.Item>
                    {this.state.isPlugsConcurrently && (
                      <Form.Item label={freire(texts.SIMULTANEOUS_POWER)}>
                        <span style={{ display: "flex" }}>
                          {freire(texts.TEXT_POWER)}
                        </span>
                        {getFieldDecorator("isPowerDividedToPlugs")(
                          <Radio.Group
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Radio value={true}>
                              {freire(texts.IS_DIVIDE_POWER)}
                            </Radio>
                            <Radio value={false}>
                              {freire(texts.FIXED_POWER_BETWEEN_PLUGS)}
                            </Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    )}
                  </Col>
                </Row>

                <Show when={"add-station-connected"}>
                  <Fragment>
                    <Row gutter={[16, 0]}>
                      <Col span={5}>
                        <Form.Item label={freire(texts.CONNECTED_QUESTION)}>
                          <Switch
                            checkedChildren={freire(texts.YES)}
                            unCheckedChildren={freire(texts.NO)}
                            onChange={(isConnected) => this.onChangeIsConnected(isConnected)}
                            checked={isConnected}
                          />
                        </Form.Item>
                      </Col>
                      {isConnected ? (
                        <Col span={7}>
                          <Form.Item label={freire(texts.STATION_ID)}>
                            {getFieldDecorator("stationID", {
                              rules: [requiredFieldRule],
                            })(
                              <Input
                                placeholder={freire(texts.STATION_ID_EX)}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      ) : null}
                    </Row>

                    {isConnected ? (
                      <Row span={5}>
                        <Col>{formList}</Col>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ marginLeft: 20, marginRight: 20 }}>
                            <Button
                              style={{
                                color: "#7A84BF",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                borderWidth: 0,
                              }}
                              onClick={() => this.addPlug()}
                            >
                              {freire(texts.ADD_POWER_PLUG)}
                            </Button>
                          </div>
                          <div style={{ marginLeft: 20, marginRight: 20 }}>
                            <Button
                              style={{
                                color: "#FC385C",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                borderWidth: 0,
                                alignSelf: "center",
                              }}
                              onClick={() =>
                                this.setState({ connectedPlugs: [] })
                              }
                            >
                              {freire(texts.REMOVE_ALL_POWER_PLUGS)}
                            </Button>
                          </div>
                        </div>
                        <Divider
                          style={{
                            marginTop: 24,
                          }}
                        />
                      </Row>
                    ) : null}

                    {isConnected ? (
                      <>
                        <Show when={"edit-test-station"}>
                          <Row gutter={16} style={{ marginTop: 20 }}>
                            <Col span={12}>
                              <Form.Item label="">
                                {getFieldDecorator("test", {
                                  valuePropName: "checked",
                                })(
                                  <Switch
                                    checkedChildren={freire(texts.YES)}
                                    unCheckedChildren={freire(texts.NO)}
                                  />
                                )}
                                <span style={{ marginLeft: 10 }}>
                                  {freire(texts.TEST_STATION)}
                                </span>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Show>

                        <Row gutter={24}>
                          <Col span={8}>
                            <Form.Item label={freire(texts.ENABLED_DATE)}>
                              {getFieldDecorator("connectedDate", {
                                rules: [
                                  {
                                    required: false,
                                  },
                                ],
                              })(
                                <DatePicker
                                  allowClear={false}
                                  style={{ width: "100%" }}
                                  placeholder={freire(texts.SELECT_DATE_TIME)}
                                  format="L LTS"
                                  showTime={{
                                    defaultValue: moment(
                                      "23:59:59",
                                      "HH:mm:ss"
                                    ),
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={8}>
                            <Form.Item label={freire(texts.STATION_TYPE)}>
                              {getFieldDecorator("stationTypeID")(
                                <Select>
                                  {listTypes.map((item) => {
                                    return (
                                      <Option key={item._id} value={item._id}>
                                        {item.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label={freire(texts.STATION_MODEL)}>
                              {getFieldDecorator("stationModelID")(
                                <Select>
                                  {listModels.map((item) => {
                                    return (
                                      <Option key={item._id} value={item._id}>
                                        {item.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label={freire(texts.STATION_LOCAL)}>
                              {getFieldDecorator("stationLocalID")(
                                <Select>
                                  {listLocals.map((item) => {
                                    return (
                                      <Option key={item._id} value={item._id}>
                                        {item.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Fragment>
                </Show>
              </Form>

              <div className="footer">
                <Button
                  loading={loadingSave}
                  onClick={this.salvar}
                  type="primary"
                >
                  {freire(texts.SAVE)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const PageNewStations = Form.create({ name: "form" })(NewStations);
export default withRouter(PageNewStations);
