import React, { useState } from "react";
import {
  Button,
  Spin,
  Collapse,
  Switch,
  Tag,
  Icon,
  notification,
  Popover,
  Tooltip,
} from "antd";
import api from "../../../../../services/api";
import { useFreire } from "../../../../../utils/freireContext";
import { formatters } from "../../../../../utils/formatters";
import { useOnUpdate } from "../../../../../hooks";
import * as texts from "../../../../../pages/Users/locales";
import "./styles.css";
import InfoRow from "../../../../../components/InfoRow";
import moment from "moment";
import { getAppByAlias } from "../../../../../constants/apps";
import { Show } from "../../../../../contexts/PermissionContext";
import { Copy } from "../../../../../components/Copy";
import { LANGUAGES } from "../../../../../config/languages";
import { AddressTooltip } from "../../../../../components/AddressTooltip";

const { Panel } = Collapse;

// TODO: add more brands
export const BRANDS = {
  VISA: "Visa",
  MASTERCARD: "Mastercard",
  DINERS: "Diners club",
  DISCOVER: "Discover",
  AMEX: "Amex",
  HIPERCARD: "Hipercard",
  ELO: "Elo",
  Wallet: "Carteira Pix",
};

const formatPaymentMethod = ({ freire, paymentMethod }) =>
  // TODO: translate
  `${BRANDS[paymentMethod.brand] ?? paymentMethod.brand ?? "Crédito"}${
    paymentMethod.suffix === undefined ? "" : ` ${paymentMethod.suffix}`
  }${
    typeof paymentMethod.balance === "number"
      ? ` (${formatters.currency(
          freire.userLanguage,
          paymentMethod.currency
        )(paymentMethod.balance / 100)})`
      : ""
  }`;

const isWallet = (paymentMethod) => paymentMethod.brand === "Wallet";

const Details = ({ userID }) => {
  const { freire } = useFreire();

  const [loading, setLoading] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [antifraudCheck, setAntifraudCheck] = useState(false);

  useOnUpdate(() => {
    if (userDetails !== null) {
      setAntifraudCheck(!userDetails.skipAntifraudCheck);
    }
  }, [userDetails]);

  const getUserDetails = async ([key]) => {
    if (userDetails === null && key === "personalData") {
      try {
        setLoading(true);

        const { data } = await api.get(`user/${userID}/address`);

        setUserDetails({ ...userDetails, ...data });
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_GET_DETAILS_USER),
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleAntifraudeCheck = async (value) => {
    try {
      setLoadingToggle(true);

      await api.patch(`/users/${userDetails._id}/skip-antifraud-check`, {
        skipAntifraudCheck: !value,
      });

      setAntifraudCheck(value);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.ANTIFRAUD_CHECK_CHANGED(value)),
      });
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ANTIFRAUD_CHECK_CHANGED_ERROR),
      });
    } finally {
      setLoadingToggle(false);
    }
  };

  const renderTagInvalid = (status, female) => {
    if (!status) {
      return null;
    }

    return (
      <Tag color="red" className="paymentStatusTag" style={{ paddingLeft: 2 }}>
        <Icon type="close" style={{ color: "red", marginRight: 3 }} />
        {freire(female ? texts.INVALID_FEMALE : texts.INVALID)}
      </Tag>
    );
  };

  const renderTagWarningInvalid = (status, female) => {
    if (!status) {
      return null;
    }

    return (
      <Tag
        color="orange"
        className="paymentStatusTag"
        style={{ paddingLeft: 6 }}
      >
        <Icon
          type="exclamation-circle"
          style={{ color: "#eb7c05", marginRight: 5 }}
        />
        {freire(female ? texts.INVALID_FEMALE : texts.INVALID)}
      </Tag>
    );
  };

  const renderPaymentMethodsTag = (primaryPaymentMethod) => {
    if (!userDetails?.paymentMethods?.[0]) {
      return renderTagWarningInvalid(true, true);
    }

    const tag = (
      <Tag className="paymentStatusTag">
        {userDetails.paymentMethods.length}
      </Tag>
    );

    const paymentMethodsWalletFirst = [
      ...userDetails.paymentMethods.filter(isWallet),
      ...userDetails.paymentMethods.filter(
        (paymentMethod) => !isWallet(paymentMethod)
      ),
    ];

    return paymentMethodsWalletFirst.length > 1 ? (
      <Popover
        content={paymentMethodsWalletFirst.map((paymentMethod) => {
          const content = (
            <Copy>{formatPaymentMethod({ freire, paymentMethod })}</Copy>
          );

          const _content =
            paymentMethod._id === primaryPaymentMethod._id ? (
              <strong>{content}</strong>
            ) : (
              content
            );

          return <div key={paymentMethod._id}>{_content}</div>;
        })}
      >
        {tag}
      </Popover>
    ) : (
      tag
    );
  };

  const renderDocumentTag = () => {
    if (userDetails.invalids?.personal?.document) {
      return renderTagInvalid(true);
    }

    return (
      // TODO: check by country - api must send
      userDetails.currency === "BRL" &&
      !userDetails.invalids?.personal?.birthdate && (
        <Tooltip placement="topRight" title={freire(texts.VALIDATE_DOCUMENT)}>
          <Button
            shape="circle"
            size="small"
            ghost
            type="primary"
            style={{ marginLeft: 16 }}
            rel="noopener noreferrer"
            target="_blank"
            // TODO: querystring properly
            href={`https://servicos.receita.fazenda.gov.br/servicos/cpf/consultasituacao/ConsultaPublica.asp?CPF=${
              userDetails?.document
            }&NASCIMENTO=${moment
              .utc(userDetails.birthdate)
              .format("DD/MM/YYYY")}`}
          >
            <Icon type="idcard" />
          </Button>
        </Tooltip>
      )
    );
  };

  const primaryPaymentMethod =
    userDetails?.paymentMethods.length > 0
      ? userDetails.paymentMethods.find(
          (paymentMethod) => paymentMethod.primary
        ) ?? userDetails.paymentMethods.find(isWallet)
      : undefined;

  return (
    <Collapse
      bordered={false}
      onChange={getUserDetails}
      expandIconPosition="right"
      style={{ marginBottom: 30 }}
    >
      <Panel
        key="personalData" // README: do not remove
        id="panel"
        header={
          <span className="drawerInfoTitle">{freire(texts.PERSONAL_DATA)}</span>
        }
      >
        {loading ? (
          <Spin
            style={{ display: "block", marginTop: 25, marginBottom: 25 }}
            tip={freire(texts.LOADING_USER_DETAILS)}
          />
        ) : (
          userDetails !== null && (
            <div style={{ marginTop: 5, paddingLeft: 24, paddingRight: 24 }}>
              <Show when={"developer"}>
                <InfoRow
                  labelSpan={7}
                  valueSpan={17}
                  label="userId"
                  value={userDetails._id}
                  bold={false}
                  allowCopy
                />
                <InfoRow
                  labelSpan={7}
                  valueSpan={17}
                  label="userID"
                  value={userDetails.userID}
                  bold={false}
                  allowCopy
                />
              </Show>
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.APPS)}:`}
                value={(userDetails?.appsLogged ?? []).map((appName) => (
                  <Popover
                    key={appName}
                    content={
                      <div>
                        {getAppByAlias(appName).name}
                        <br />
                        <br />
                        {/* TODO: translate */}
                        <strong>Habilitou as notificações:</strong>{" "}
                        {userDetails?.fcmToken?.[appName] ? "Sim" : "Não"}
                      </div>
                    }
                  >
                    <img
                      src={getAppByAlias(appName).favicon}
                      width={18}
                      style={{ marginRight: 8, marginBottom: 2 }}
                      alt={getAppByAlias(appName).name}
                    />
                  </Popover>
                ))}
                bold={false}
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.LANGUAGE)}:`}
                value={
                  LANGUAGES[userDetails?.language]
                    ? freire(LANGUAGES[userDetails.language].text)
                    : null
                }
                bold={false}
                allowCopy
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.NAME)}:`}
                value={userDetails?.displayName}
                bold={false}
                allowCopy
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.FULL_NAME)}:`}
                value={userDetails?.fullName}
                extraItems={renderTagInvalid(
                  userDetails.invalids?.personal?.fullName
                )}
                bold={false}
                allowCopy
              />
              {/* TODO: email verificado */}
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.EMAIL)}:`}
                value={userDetails?.email}
                bold={false}
                allowCopy
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.DOCUMENT)}:`}
                value={userDetails?.document}
                extraItems={renderDocumentTag()}
                bold={false}
                {...((userDetails?.document ?? "").length > 0 && {
                  tooltip: (
                    <div>
                      <strong>raw:</strong>{" "}
                      <Copy>{userDetails.document.replace(/[^\d]/g, "")}</Copy>
                    </div>
                  ),
                  tooltipWhen: "developer",
                })}
                allowCopy
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.BIRTHDATE)}:`}
                value={
                  userDetails?.birthdate
                    ? moment.utc(userDetails.birthdate).format("L")
                    : userDetails?.birthdate
                }
                extraItems={renderTagInvalid(
                  userDetails.invalids?.personal?.birthdate,
                  true
                )}
                bold={false}
                {...((userDetails?.birthdate ?? "").length > 0 && {
                  tooltip: (
                    <div>
                      <div>
                        <strong>{freire(texts.AGE)}:</strong>{" "}
                        <Copy>
                          {freire(
                            texts.YEARS_OLD(
                              moment().diff(userDetails.birthdate, "years")
                            )
                          )}
                        </Copy>
                      </div>
                      <Show when={"developer"}>
                        <br />
                        <div>
                          <strong>raw:</strong>{" "}
                          <Copy>{userDetails.birthdate}</Copy>
                        </div>
                      </Show>
                    </div>
                  ),
                })}
                allowCopy
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.PHONE)}:`}
                value={formatters.phone(userDetails?.phone)}
                extraItems={renderTagInvalid(
                  userDetails.invalids?.personal?.phone
                )}
                bold={false}
                {...((userDetails?.phone ?? "").length > 0 && {
                  tooltip: (
                    <div>
                      <strong>raw:</strong> <Copy>{userDetails.phone}</Copy>
                    </div>
                  ),
                  tooltipWhen: "developer",
                })}
                allowCopy
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.ADDRESS)}:`}
                value={formatters.formatAddress(userDetails?.address)}
                extraItems={renderTagInvalid(
                  userDetails.invalids?.address?.logradouro
                )}
                bold={false}
                {...(userDetails?.address && {
                  tooltip: (
                    <AddressTooltip
                      address={userDetails.address}
                    />
                  ),
                })}
                allowCopy
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.PAYMENT_METHOD)}:`}
                value={
                  userDetails?.paymentMethods.length > 0 &&
                  primaryPaymentMethod ? (
                    <Copy>
                      {formatPaymentMethod({
                        freire,
                        paymentMethod: primaryPaymentMethod,
                      })}
                    </Copy>
                  ) : null
                }
                extraItems={renderPaymentMethodsTag(primaryPaymentMethod)}
                // TODO: exibir se expirou
                bold={false}
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.NEGATIVE_BALANCE)}:`}
                value={
                  userDetails?.balance !== 0 ? (
                    <Copy
                      style={{
                        color: userDetails.balance < 0 ? "#f40000" : "#b670c2",
                      }}
                    >
                      {formatters.currency(
                        freire.userLanguage,
                        userDetails.currency
                      )(
                        (userDetails.balance < 0
                          ? userDetails.balance * -1
                          : userDetails.balance) / 100
                      )}
                    </Copy>
                  ) : null
                }
                bold={false}
                // noBorder={process.env.REACT_APP_ENV_NAME === "produção"}
                // {...((userDetails?.phone ?? "").length > 0 && {
                //   tooltip: userDetails.phone,
                // })}
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.IS_PERSON_FRAUDSTER)}:`}
                value={
                  userDetails?.antifraud?.isFraudster ? (
                    Object.keys(userDetails?.antifraud?.pendingBalances).map((key) =>
                      <div>
                        <Copy>{key}</Copy>{" "}
                        <strong style={{color: '#f40000' }}>{
                        formatters.currency(
                          freire.userLanguage,
                          userDetails.currency
                        )(userDetails?.antifraud?.pendingBalances[key] / 100)}
                        </strong><br/>
                      </div>
                    )
                  ) : freire(texts.NO)
                }
                bold={true}
              />
              {/* TODO: constante exibir antifraude? */}
              <div className="containerSwitch">
                <Switch
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                  checked={antifraudCheck}
                  onChange={toggleAntifraudeCheck}
                  loading={loadingToggle}
                />
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.USER_ANTIFRAUD_CHECK)}
                </span>
              </div>
            </div>
          )
        )}
      </Panel>
    </Collapse>
  );
};

export default Details;
