import React, { useContext } from "react";
import { Button, Col, Form, Input, InputNumber, Row, Switch } from "antd";
import { FreireContext } from "../../utils/freireContext";
import {
  ACTIVATION_FEE,
  BUTTON_KEEP,
  BUTTON_CHANGE,
  YES,
  NO,
  ACTIVATION_FEE_VALUE,
  ACTIVATION_FEE_KWH_VALUE_EXEMPTION,
  ACTIVATION_FEE_CHARGE_VALUE_EXEMPTION,
} from "../../locales";
import { FormComponentProps } from "antd/lib/form";
import { formatarMoeda } from "../FormatingUtils";

const enabled_activation_fee = {
  activation_fee: {
    enabled: true,
    value: 0,
    exemption_time_limit_seconds: 0,
    exemption_lower_charge_value: 100,
    exemption_lower_stop_value: 1,
  },
};

export const disabled_activation_fee = {
  activation_fee: {
    enabled: false,
    value: 0,
    exemption_time_limit_seconds: 0,
    exemption_lower_charge_value: 100,
    exemption_lower_stop_value: 1,
  },
};

type TActivationFee = {
  enabled: boolean;
  value: number;
  exemption_time_limit_seconds: number;
  exemption_lower_charge_value: number;
  exemption_lower_stop_value: number;
};

type TActivationFeeProps = {
  form: FormComponentProps["form"];
  loading: boolean;
  bacthOperation?: boolean;
  selectedCompanyCurrency: string;
  activation_fee: TActivationFee;
};
export const ActivationFee = ({
  form,
  loading,
  bacthOperation = false,
  selectedCompanyCurrency,
  activation_fee,
}: TActivationFeeProps) => {
  const { freire } = useContext(FreireContext);
  const { getFieldDecorator, getFieldValue } = form;

  const [keepActivationFeeValue, setKeepActivationFeeValue] =
    React.useState(true);

  const resetActivationFee = () => {
    form.setFieldsValue({
      activation_fee: {
        enabled: undefined,
        value: undefined,
        exemption_time_limit_seconds: undefined,
        exemption_lower_charge_value: undefined,
        exemption_lower_stop_value: undefined,
      },
    });

    setKeepActivationFeeValue(true);
  };

  const handleActivationFeeChange = (keep: boolean) => {
    setKeepActivationFeeValue(keep);
    if (keep) {
      form.getFieldsValue().paymentCharge?.enabled === false
        ? form.setFieldsValue(disabled_activation_fee)
        : resetActivationFee();
    } else {
      form.setFieldsValue(disabled_activation_fee);
    }
  };

  return (
    <div className={bacthOperation ? "margin-container-style" : ""}>
      <div className={bacthOperation ? "container-contents" : ""}>
        <span style={{ color: "#434341", fontWeight: "bold" }}>
          {freire(ACTIVATION_FEE)}
        </span>
        {bacthOperation && (
          <div className="keep-btn-change-btn">
            <Button
              type={keepActivationFeeValue ? "primary" : "default"}
              style={{
                fontWeight: "bold",
                color: keepActivationFeeValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handleActivationFeeChange(true)}
            >
              {freire(BUTTON_KEEP)}
            </Button>
            <Button
              type={!keepActivationFeeValue ? "primary" : "default"}
              style={{
                fontWeight: "bold",
                color: !keepActivationFeeValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handleActivationFeeChange(false)}
            >
              {freire(BUTTON_CHANGE)}
            </Button>
          </div>
        )}
      </div>
      <div
        style={
          bacthOperation
            ? {
                display: !keepActivationFeeValue ? "block" : "none",
              }
            : {}
        }
      >
        <Row gutter={14} style={{ marginTop: 8 }}>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator("activation_fee.enabled", {
                valuePropName: "checked",
              })(
                <Switch
                  disabled={loading}
                  checkedChildren={freire(YES)}
                  unCheckedChildren={freire(NO)}
                  onChange={(event) => {
                    if (!event) {
                      form.setFieldsValue(disabled_activation_fee);
                    } else {
                      form.setFieldsValue(enabled_activation_fee);
                    }
                  }}
                />
              )}
              <span style={{ marginLeft: 10 }}>{freire(ACTIVATION_FEE)}</span>
            </Form.Item>
          </Col>
          <Col
            span={6}
            style={activation_fee?.enabled ? {} : { display: "none" }}
          >
            <Form.Item label={freire(ACTIVATION_FEE_VALUE)}>
              {getFieldDecorator("activation_fee.value", {
                normalize: (input: string) => {
                  return input ? formatarMoeda(input) : "0,00";
                },
              })(<Input prefix={selectedCompanyCurrency} disabled={loading} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={14}
          style={
            getFieldValue("activation_fee.enabled") ? {} : { display: "none" }
          }
        >
          <Row>
            <Col span={14}>
              <Form.Item label={freire(ACTIVATION_FEE_KWH_VALUE_EXEMPTION)}>
                {getFieldDecorator("activation_fee.exemption_lower_stop_value")(
                  <InputNumber disabled={loading} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label={freire(
                  ACTIVATION_FEE_CHARGE_VALUE_EXEMPTION(selectedCompanyCurrency)
                )}
              >
                {getFieldDecorator(
                  "activation_fee.exemption_lower_charge_value",
                  {
                    normalize: (input: string) => {
                      return input && formatarMoeda(String(input));
                    },
                  }
                )(
                  <Input prefix={selectedCompanyCurrency} disabled={loading} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ display: "none" }}>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator(
                  "activation_fee.exemption_time_limit_seconds"
                )(<InputNumber disabled={loading} />)}
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};
