import { ILanguageTranslation } from "../../interfaces/languages";

export const SELECT_COUNTRY_PLACEHOLDER: ILanguageTranslation = {
  pt: "Selecione o país",
  // TODO: translate es
  // TODO: translate en
};

export const LOADING: ILanguageTranslation = {
  pt: "Carregando...",
  es: "Cargando...",
  en: "Loading...",
};

export const SELECT_COUNTRY_LABEL: ILanguageTranslation = {
  pt: "País de atuação",
  // TODO: translate es
  // TODO: translate en
};

export const SELECT_COUNTRY_WARN: ILanguageTranslation = {
  pt: "Atenção: esse campo não poderá ser alterado após a criação da empresa.",
  // TODO: translate es
  // TODO: translate en
};

export const REQUIRED: ILanguageTranslation = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const ERROR_LIST_COUNTRIES: ILanguageTranslation = {
  pt: "Não foi possível carregar a lista de países",
  // TODO: translate es
  // TODO: translate en
};

export const OOPS: ILanguageTranslation = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};
