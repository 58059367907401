import { WeekProps } from "../interfaces";
import { Button } from "antd";
import { useFreire } from "../../../utils/freireContext";
import { useContext } from "react";
import * as texts from "../locales";
import "../index.css";
import { BusinessHourContext } from "../index";
import Day from "./Day";

const cssButtonClass = (open: boolean) => {
  return open ? "buttonChecked" : "buttonUnchecked";
};

export default function Week({ week }: WeekProps) {
  const { freire } = useFreire();

  const { loading, onChangeOpenDay, disabled } =
    useContext(BusinessHourContext);

  return (
    <div className="weekContainer">
      <span className="titleWeek">{freire(texts.DAYS_OF_WEEK)}</span>
      <div className="weekButtonsRow">
        {Object.entries(week).map((aux, dayIndex) => {
          const [weekday, day] = aux;

          return (
            <Button
              disabled={loading || disabled}
              key={weekday}
              className={cssButtonClass(day.open)}
              onClick={() => onChangeOpenDay(weekday)}
            >
              {freire(texts.DAYS)[dayIndex]}
            </Button>
          );
        })}
      </div>
      <div className="weekWEEK_DAYSColumn">
        <span className="titleWeek">{freire(texts.HOUR)}</span>
        {Object.entries(week).map((aux, dayIndex) => {
          const [weekday, day] = aux;

          if (!day?.open) {
            return null;
          }
          return (
            <Day
              key={weekday}
              day={day}
              weekDay={weekday}
              dayName={freire(texts.DAYS)[dayIndex]}
            />
          );
        })}
      </div>
    </div>
  );
}
