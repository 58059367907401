export const ERROR_SAVE_STICKER = {
  pt: "Erro ao salvar o adesivo",
  es: "Error al guardar el adhesivo",
  en: "Error saving sticker",
};

export const LOGO = {
  pt: "logotipo",
  es: "logotipo",
  en: "logo",
};

export const DOWNLOAD = {
  pt: "Download",
  es: "Descarga",
  en: "Download",
};

export const STICKER = {
  pt: "Adesivo",
  es: "Adhesivo",
  en: "Adesivo",
};
