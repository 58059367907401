export const NEW_LOCATION = {
  pt: "Nova Localização",
  es: "Nueva Localidad",
  en: "New Location",
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const LOCATIONS = {
  pt: "Localizações",
  es: "Localizaciones",
  en: "Locations",
};

export const LISTING = {
  pt: "Listagem",
  es: "Listado",
  en: "Listing",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const ADDRESS = {
  pt: "Endereço",
  es: "Dirección",
  en: "Address",
};

export const COUNTRY = {
  pt: "País",
  es: "Pais",
  en: "Country",
};

export const COMPANY = {
  pt: "Empresa",
  es: "Compañia",
  en: "Company",
};

export const OPTIONS = {
  pt: "Opções",
  es: "Opiciones",
  in: "Options",
};

export const DELETE = {
  pt: "Deletar",
  es: "Borrar",
  en: "Delete",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const ERROR_LIST_COMPANIES = {
  pt: "Não foi possível carregar a lista de empresas",
  es: "No fue posible cargar la lista de empresas",
  en: "It was not possible to load the list of companies",
};

export const ERROR_LIST_COUNTRIES = {
  pt: "Não foi possível carregar a lista de países",
  es: "No fue posible cargar la lista de paises",
  en: "It was not possible to load the list of countries",
};

export const ERROR_LIST_LOCATIONS = {
  pt: "Não foi possível carregar a lista de localizações",
  es: "No fue posible cargar la lista de localizaciones",
  en: "It was not possible to load the list of locations",
};

export const SEARCH_BY_NAME = {
  pt: "Pesquise pelo nome",
  es: "Buscar por nombre",
  en: "Search by name",
};

export const SELECT_COMPANY = {
  pt: "Selecione a empresa",
  es: "Seleccione la compañia",
  en: "Select company",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const ADD_LOCATION = {
  pt: "Adicionar Localização",
  es: "Agregar Localidad",
  en: "Add Location",
};

export const LATITUDE = {
  pt: "Latitude",
  es: "Latitud",
  en: "Latitude",
};

export const LONGITUDE = {
  pt: "Longitude",
  es: "Longitud",
  en: "Longitude",
};

export const LATITUDE_EX = {
  pt: "Ex.: -00.0000",
  es: "p.ej.: -00.0000",
  en: "Example: -00.0000",
};

export const PHONE = {
  pt: "Telefone",
  es: "Teléfono",
  en: "Phone",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const WENT_WRONG = {
  pt: "Algo deu errado!",
  es: "¡Algo ha salido mal!",
  en: "Something went wrong!",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡De acuerdo!",
  en: "All right!",
};

export const LOCATION_ADD_SUCCESS = {
  pt: "Localização adicionada com sucesso!",
  es: "¡Localidad agregada con èxito!",
  en: "Location added successfully!",
};

export const PRIVATE_LOCATION_QUESTION = {
  pt: "Localização privada?",
  es: "¿Localidad privada?",
  en: "Private location?",
};

export const YES = {
  pt: "Sim",
  es: "SÍ",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const FREE_PARKING_QUESTION = {
  pt: "Estacionamento Grátis?",
  es: "¿Estacionamiento Gratuito?",
  en: "Free parking",
};


export const SELECT_COMPANY_WARN = {
  pt: "Só será possível selecionar empresas que estão no país já selecionado",
  en: "Only companies from the selected country can be selected",
  es: "Só es posible seleccionar empresas que estan en el país seleccionado",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Station",
};