import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import firebase from "./services/firebaseConfig";

class PrivateRoute extends Component {
  state = {
    haveAcces: false,
    loaded: false,
  }

  componentDidMount() {
    this.checkAcces();
  }

  checkAcces = () => {
    const { history } = this.props;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ haveAcces: true, loaded: true });
      } else {
        history.push('/');
      }
    })
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, haveAcces } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={props => {
          return haveAcces ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }}
      />
    );
  }
}

export default withRouter(PrivateRoute);
