export const ACTIVATION_FEE = {
  pt: "Taxa de ativação",
  es: "Tarifa de activación",
  en: "Activation fee",
}

export const ACTIVATION_FEE_VALUE = {
  pt: "Custo da ativação",
  es: "Costo de activación",
  en: "Activation fee value",
};

export const BUTTON_KEEP = {
  pt: "Manter atual",
  es: "Mantener actual",
  en: "Keep current",
};

export const BUTTON_CHANGE = {
  pt: "Modificar",
  es: "Cambiar",
  en: "Change",
};

export const YES = {
  pt: "Sim",
  es: "SÍ",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const ACTIVATION_FEE_KWH_VALUE_EXEMPTION = {
  pt: "Carga mínima para cobrança (kWh)",
  es: "Carga mínima para cobranza (kWh)",
  en: "Minimum charge (kWh)",
};

export const ACTIVATION_FEE_CHARGE_VALUE_EXEMPTION = (currency: string) => ({
  pt: `Preço mínimo da carga para cobrança (${currency})`,
  es: `Preço mínimo de la carga para cobranza (${currency})`,
  en: `Minimum charge value (${currency})`,
});

export const NO_ACTIVATION_FEE = {
  pt: "Sem taxa de ativação",
  es: "Sin tarifa de activación",
  en: "No activation fee",
};

export const CURRENT_ACTIVATION_FEE = {
  pt: "Taxa de ativação atual",
  es: "Tarifa de activación actual",
  en: "Current activation fee",
};