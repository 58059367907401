export const WARNING_VALID_HOUR = {
  pt: "Insira um horário válido.",
  en: "Insert a valid hour.",
  es: "Inserte una hora válida.",
};

export const TIMEZONE_REQUIRED = {
  pt: "Selecione uma localização válida.",
  en: "Select a valid location.",
  es: "Seleccione una ubicación válida.",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡De acuerdo!",
  en: "All right!",
};

export const BUSINESS_HOUR_EDITED = {
  pt: "Horário de funcionamento salvo com sucesso",
  en: "Business hour edited successfully",
  es: "Horario de funcionamiento guardado correctamente",
};

export const BUSINESS_HOURS_TITLE = {
  pt: "AJUSTES DO HORÁRIO DE FUNCIONAMENTO SISTEMATIZADO",
  en: "SYSTEMATIC BUSINESS HOURS SETTINGS",
  es: "AJUSTES DEL HORARIO DE FUNCIONAMIENTO SISTEMATIZADO",
};

export const DAYS_OF_WEEK = {
  pt: "Dias da semana",
  en: "Days of the week",
  es: "Días de la semana",
};

export const DAYS = {
  pt: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
  en: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  es: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ],
};

export const TWENTY_FOUR_HOURS = {
  pt: "Funcionará 24h",
  en: "Works 24h",
  es: "Trabaja 24h",
};

export const HOUR = {
  pt: "Horário",
  en: "Hour",
  es: "Horario",
};

export const OF = {
  pt: "Das ",
  en: "From ",
  es: "De ",
};

export const TO = {
  pt: " às ",
  en: " to ",
  es: " hasta ",
};
export const TIMEZONE = {
  pt: "Fuso horário",
  en: "Time zone",
  es: "Zona horaria",
};

export const USE_LOCATION = {
  pt: "Use a localização",
  en: "Use Location",
  es: "Usar ubicación",
};

export const NEW_HOUR = {
  pt: "Horário Adicional:",
  en: "Additional hour:",
  es: "Horario Adicional:",
};

export const COPY_IN_ALL = {
  pt: "Copiar em todos",
  en: "Copy in all",
  es: "Copiar en todos",
};

export const ADDITIONAL_HOUR = {
  pt: "Horário adicional",
  en: "Additional Hour",
  es: "Horario adicional",
};

export const SAVED_HOUR = {
  pt: "Salvo com sucesso",
  en: "Saved successfully",
  es: "Guardado correctamente",
};

export const SAVE_HOUR_WARNING = {
  pt: "Confirme ao lado as alterações nos horários.",
  en: "Confirm the changes in the hours.",
  es: "Confirme los cambios en los horarios.",
};

export const SAVE_HOUR = {
  pt: "Salvar horário",
  en: "Save Hour",
  es: "Guardar horário",
};

export const SEMI_PUBLIC_STATION = {
  pt: "Estação semipública",
  en: "Semi Public Station",
  es: "Estación semipública",
};

export const SEMI_PUBLIC_STATION_DESCRIPTION = {
  pt: "Ativando esta opção, você permite que um grupo específico tenha exclusividade na estação de carregamento por um período definido.",
  en: "By activating this option, you allow a specific group to have exclusive access to the charging station for a defined period.",
  es: "Activando esta opción, usted permite que un grupo específico tenga acceso exclusivo a la estación de carga por un período definido.",
};

export const PUBLIC = {
  pt: "Público",
  en: "Public",
  es: "Público",
};

export const PRIVATE = {
  pt: "Privado",
  en: "Private",
  es: "Privado",
}

export const PUBLIC_HOURS_INFO = {
  pt: "Qualquer usuário pode utilizar a estação dentro deste horário.",
  en: "Any user can use the station within this hour.",
  es: "Cualquier usuario puede utilizar la estación dentro de este horario.",
};

export const PUBLIC_HOURS_TITLE = {
  pt: "Horário Público",
  en: "Public Hour",
  es: "Horario Público",
};

export const PRIVATE_HOURS_INFO = {
  pt: "O local torna-se restrito, permitindo apenas que usuários listados nos grupos de sua empresa iniciem a carga.",
  en: "The location becomes restricted, allowing only users listed in your company's groups to start charging.",
  es: "El local se restringe, permitiendo solo que los usuarios listados en los grupos de su empresa inician la carga.",
};

export const PRIVATE_HOURS_TITLE = {
  pt: "Horário Privado",
  en: "Private Hour",
  es: "Horario Privado",
}