import React, { useState } from "react";
import { Form, Row, Col, Switch, Radio, Input, Button } from "antd";
import * as texts from "./locales";
import { useFreire } from "../../utils/freireContext";
import { formatarMoeda } from "../FormatingUtils";
import "./index.css";
import { disabledIdleFee } from "../../pages/Stations/BatchOperation";

interface ISelectPayment {
  getFieldDecorator: any;
  isLoading: boolean;
  forms: any;
  paymentCharge: any;
  selectedCompanyCurrency: string;
  loadingPaymentChargeValue: boolean;
  idleFee: any;
  keepIdleFeeValue: boolean;
  resetIdleFee: () => void;
}

const SelectPayment: React.FC<ISelectPayment> = ({
  getFieldDecorator,
  isLoading,
  forms,
  paymentCharge,
  selectedCompanyCurrency,
  loadingPaymentChargeValue,
  idleFee,
  keepIdleFeeValue,
  resetIdleFee,
}) => {
  const { freire } = useFreire();
  const [paymentKeepButtonType, setPaymentKeepButtonType] = useState<
    "default" | "primary"
  >("primary");
  const [paymentChangeButtonType, setPaymentChangeButtonType] = useState<
    "default" | "primary"
  >("default");

  const [couponKeepButtonType, setCouponKeepButtonType] = useState<
    "default" | "primary"
  >("primary");
  const [couponChangeButtonType, setCouponChangeButtonType] = useState<
    "default" | "primary"
  >("default");

  const [posPayKeepButtonType, setPosPayKeepButtonType] = useState<
    "default" | "primary"
  >("primary");
  const [posPayChangeButtonType, setPosPayChangeButtonType] = useState<
    "default" | "primary"
  >("default");

  const [keepPaymentValue, setKeepPaymentValue] = useState(true);
  const [keepCouponValue, setKeepCouponValue] = useState(true);
  const [keepPosPayValue, setKeepPosPayValue] = useState(true);

  const handlePaymentButtonClick = (shouldKeep: boolean) => {
    setKeepPaymentValue(shouldKeep);

    if (shouldKeep) {
      setPaymentKeepButtonType("primary");
      setPaymentChangeButtonType("default");
      forms.setFieldsValue({
        paymentCharge: {
          enabled: undefined,
        },
      });
      if (!idleFee?.enabled) {
        resetIdleFee();
      }
    } else {
      setPaymentKeepButtonType("default");
      setPaymentChangeButtonType("primary");
      forms.setFieldsValue({
        paymentCharge: {
          enabled: false,
          method: "",
          value: 0,
          period: "",
          perTime: 1,
          timeWindow: "",
        },
        ...disabledIdleFee,
      });
    }
  };

  const handleCouponButtonClick = (shouldKeep: boolean) => {
    setKeepCouponValue(shouldKeep);
    if (shouldKeep) {
      setCouponKeepButtonType("primary");
      setCouponChangeButtonType("default");
      forms.setFieldsValue({
        acceptCoupon: undefined,
      });
    } else {
      setCouponKeepButtonType("default");
      setCouponChangeButtonType("primary");
    }
  };

  const handlePosPayButtonClick = (shouldKeep: boolean) => {
    setKeepPosPayValue(shouldKeep);
    if (shouldKeep) {
      setPosPayKeepButtonType("primary");
      setPosPayChangeButtonType("default");
      forms.setFieldsValue({
        posPayment: undefined,
      });
    } else {
      setPosPayKeepButtonType("default");
      setPosPayChangeButtonType("primary");
    }
  };

  const handleSwitchChange = (enable: boolean) => {
    if (!enable) {
      forms.setFieldsValue({
        paymentCharge: {
          enabled: false,
          method: "",
          value: 0,
          period: "",
          perTime: 1,
          timeWindow: "",
        },
        ...disabledIdleFee,
      });
    } else {
      if (keepIdleFeeValue) {
        forms.setFieldsValue({
          idleFee: {
            enabled: undefined,
          },
        });
      }
    }
  };

  return (
    <div>
      <div className="margin-container-style">
        <div className="container-contents">
          <span style={{ color: "#434341", fontWeight: "bold" }}>
            {freire(texts.PAYMENT_ON_CHARGE)}
          </span>
          <div className="keep-btn-change-btn">
            <Button
              type={paymentKeepButtonType}
              style={{
                fontWeight: "bold",
                color: keepPaymentValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handlePaymentButtonClick(true)}
            >
              {freire(texts.BUTTON_KEEP)}
            </Button>
            <Button
              type={paymentChangeButtonType}
              style={{
                fontWeight: "bold",
                color: !keepPaymentValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handlePaymentButtonClick(false)}
            >
              {freire(texts.BUTTON_CHANGE)}
            </Button>
          </div>
        </div>

        <Row
          gutter={24}
          style={!keepPaymentValue ? { marginTop: 8 } : { display: "none" }}
        >
          <Col span={24}>
            <Form.Item label="">
              {getFieldDecorator("paymentCharge.enabled", {
                valuePropName: "checked",
              })(
                <Switch
                  disabled={isLoading}
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                  onChange={(event) => handleSwitchChange(event)}
                />
              )}
              <span style={{ marginLeft: 10 }}>
                {freire(texts.PAYMENT_CHARGE)}
              </span>
            </Form.Item>
          </Col>
        </Row>

        <Row
          gutter={24}
          style={!paymentCharge?.enabled ? { display: "none" } : {}}
        >
          <Col span={12}>
            <Form.Item label={freire(texts.METHOD)}>
              {getFieldDecorator("paymentCharge.method")(
                <Radio.Group
                  disabled={isLoading}
                  onChange={() =>
                    forms.resetFields([
                      "paymentCharge.value",
                      "paymentCharge.period",
                      "paymentCharge.timeWindow",
                      "paymentCharge.perTime",
                    ])
                  }
                  buttonStyle="solid"
                >
                  <Radio.Button value="kWh">
                    {freire(texts.METHOD_KWH)}
                  </Radio.Button>
                  <Radio.Button value="Time">
                    {freire(texts.METHOD_TIME)}
                  </Radio.Button>
                  <Radio.Button value="Fixo">
                    {freire(texts.METHOD_FIXED)}
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              style={
                paymentCharge?.method === "Time" || !paymentCharge?.enabled
                  ? { display: "none" }
                  : {}
              }
              label={`${freire(texts.VALUE)}${
                paymentCharge?.method !== ""
                  ? paymentCharge?.method === "kWh"
                    ? ` ${freire(texts.OF_KWH)}`
                    : ` ${freire(texts.FIXED)}`
                  : ""
              } (${selectedCompanyCurrency})`}
            >
              {getFieldDecorator("paymentCharge.value", {
                normalize: (input: any) => {
                  return input ? formatarMoeda(String(input)) : "0,00";
                },
              })(
                <Input
                  disabled={loadingPaymentChargeValue}
                  placeholder={freire(texts.VALUE_EX)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <div
          style={paymentCharge?.method !== "Time" ? { display: "none" } : {}}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label={freire(texts.METHOD_CHARGE)}>
                {getFieldDecorator("paymentCharge.period")(
                  <Radio.Group disabled={isLoading} buttonStyle="solid">
                    <Radio.Button value="relative">
                      {freire(texts.METHOD_TIME_RELATIVE)}
                    </Radio.Button>
                    <Radio.Button value="fixed">
                      {freire(texts.METHOD_TIME_FIXED)}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item label={freire(texts.VALUE)}>
                {getFieldDecorator("paymentCharge.value", {
                  normalize: (input = Number) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                })(
                  <Input
                    disabled={loadingPaymentChargeValue}
                    placeholder={freire(texts.VALUE_EX)}
                  />
                )}
              </Form.Item>
            </Col>
            <div className="payment-period">{freire(texts.PAYMENT_PERIOD)}</div>
            <div className="div-payment-description">
              <Col span={3}>
                <div style={{ marginTop: 4 }}>
                  {freire(texts.METHOD_DESCRIPTION)}
                </div>
              </Col>
              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator("paymentCharge.perTime")(
                    <Input disabled={loadingPaymentChargeValue} />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator("paymentCharge.timeWindow")(
                    <Radio.Group disabled={isLoading} buttonStyle="solid">
                      <Radio.Button value="min">
                        {freire(texts.METHOD_TIME_MIN)}
                      </Radio.Button>
                      <Radio.Button value="hour">
                        {freire(texts.METHOD_TIME_HOUR)}
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </div>
          </Row>
        </div>
      </div>

      <div className="margin-container-style">
        <div className="container-contents">
          <span style={{ color: "#434341", fontWeight: "bold" }}>
            {freire(texts.COUPON_ON_CHARGE)}
          </span>
          <div className="keep-btn-change-btn">
            <Button
              type={couponKeepButtonType}
              style={{
                fontWeight: "bold",
                color: keepCouponValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handleCouponButtonClick(true)}
            >
              {freire(texts.BUTTON_KEEP)}
            </Button>
            <Button
              type={couponChangeButtonType}
              style={{
                fontWeight: "bold",
                color: !keepCouponValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handleCouponButtonClick(false)}
            >
              {freire(texts.BUTTON_CHANGE)}
            </Button>
          </div>
        </div>

        {!keepCouponValue && (
          <div>
            <Row gutter={24} style={{ marginTop: 8 }}>
              <Col span={24}>
                <Form.Item label="">
                  {getFieldDecorator("acceptCoupon", {
                    valuePropName: "checked",
                    initialValue: false,
                  })(
                    <Switch
                      disabled={isLoading}
                      checkedChildren={freire(texts.YES)}
                      unCheckedChildren={freire(texts.NO)}
                    />
                  )}
                  <span style={{ marginLeft: 10 }}>
                    {freire(texts.ACCEPT_COUPONS_CHARGE)}
                  </span>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </div>

      <div className="margin-container-style">
        <div className="container-contents">
          <span style={{ color: "#434341", fontWeight: "bold" }}>
            {freire(texts.POST_PAYMENT_CHARGE)}
          </span>
          <div className="keep-btn-change-btn">
            <Button
              type={posPayKeepButtonType}
              style={{
                fontWeight: "bold",
                color: keepPosPayValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handlePosPayButtonClick(true)}
            >
              {freire(texts.BUTTON_KEEP)}
            </Button>
            <Button
              type={posPayChangeButtonType}
              style={{
                fontWeight: "bold",
                color: !keepPosPayValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handlePosPayButtonClick(false)}
            >
              {freire(texts.BUTTON_CHANGE)}
            </Button>
          </div>
        </div>

        {!keepPosPayValue && (
          <div>
            <Row gutter={24} style={{ marginTop: 8 }}>
              <Col span={24}>
                <Form.Item label="">
                  {getFieldDecorator("posPayment", {
                    valuePropName: "checked",
                    initialValue: false,
                  })(
                    <Switch
                      disabled={isLoading}
                      checkedChildren={freire(texts.YES)}
                      unCheckedChildren={freire(texts.NO)}
                    />
                  )}
                  <span style={{ marginLeft: 10 }}>
                    {freire(texts.POS_PAYMENT)}
                  </span>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectPayment;
