import React, { useEffect, useState } from "react";
import apiOcpp from "../../../services/apiOcpp";
import "./index.css";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import TablePagination from "../../../components/TablePagination";
import dots from "../../../assets/dots.png";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useAccess } from "../../../contexts/PermissionContext";
import Show from "../../../contexts/PermissionContext/Show";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";

const { confirm } = Modal;

const { RangePicker } = DatePicker;
const { Option } = Select;

const ChargingIndicator = () => (
  <div className="viewStatus">
    <div className="Charging" />
  </div>
);

const ListTransactions = ({ form, history }) => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [totalTransactions, setTotalTransactions] = useState(0);

  const { freire } = useFreire();

  const { hasPermission } = useAccess();

  const canShowDetailsOcppCharge = hasPermission("show-details-ocpp-charge");

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id, data, index) => {
        return (
          <span
            className="id"
            style={
              canShowDetailsOcppCharge ? { textDecoration: "underline" } : {}
            }
          >
            {id}
          </span>
        );
      },
    },
    {
      title: freire(texts.STATION),
      dataIndex: "stationId",
      key: "stationId",
      render: (stationId, data) => {
        return (
          <div style={{ flexDirection: "column" }}>
            <div className="stationName">
              {data.stopValue === null && <ChargingIndicator />}
              {data.stationName || ""}
            </div>
            <div className="stationId">{stationId}</div>
          </div>
        );
      },
    },
    {
      title: freire(texts.CONNECTOR),
      dataIndex: "connectorId",
      key: "connectorId",
    },
    {
      title: freire(texts.TAG),
      dataIndex: "idTag",
      key: "idTag",
    },
    {
      title: freire(texts.START),
      dataIndex: "startEventTimestamp",
      key: "startEventTimestamp",
      render: (startEventTimestamp, data) => {
        return (
          <div style={{ flexDirection: "column" }}>
            <div>
              {startEventTimestamp && moment(startEventTimestamp).calendar()}
            </div>
          </div>
        );
      },
    },
    {
      title: freire(texts.END_AT),
      dataIndex: "stopTimestamp",
      key: "stopTimestamp",
      render: (stopTimestamp, data) => {
        return (
          <div style={{ flexDirection: "column" }}>
            <div>
              {stopTimestamp ? moment(stopTimestamp).calendar() : "----"}
            </div>
          </div>
        );
      },
    },
    {
      title: freire(texts.START_VALUE),
      dataIndex: "startValue",
      key: "startValue",
    },
    {
      title: freire(texts.STOP_VALUE),
      dataIndex: "stopValue",
      key: "stopValue",
      render: (stopValue, data) => {
        return (
          <div style={{ flexDirection: "column" }}>
            <div>{stopValue || "----"}</div>
          </div>
        );
      },
    },
    {
      title: freire(texts.ACTIONS),
      key: "acoes",
      render: (_, data) =>
        data.stopValue === null && (
          <Show when={"execute-stop-charge"}>
            <Dropdown overlay={menu(data.stationId, data.id)}>
              <div>
                <img src={dots} style={{ width: 21 }} alt="" />
              </div>
            </Dropdown>
          </Show>
        ),
    },
  ];

  const fetchTransactions = async (params = {}) => {
    try {
      setLoadingTable(true);

      const {
        data: { docs, totalDocs },
      } = await apiOcpp.get(`/transactions`, {
        params: { ...params, ...pagination },
      });

      setTransactions(docs);
      setTotalTransactions(totalDocs);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_CHARGES),
      });
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (totalTransactions > 0) {
      handleFiltersAndFetch({ resetPage: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const getParamsFilter = async () => {
    try {
      const values = await form.validateFields();

      const params = {};

      const { dateTime, active, id } = values;
      if (dateTime && dateTime.length > 0) {
        const startDate = dateTime[0]
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toJSON();

        const endDate = dateTime[1]
          .utcOffset(0)
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .toJSON();

        params.startDate = startDate;
        params.endDate = endDate;
      }

      if (active) {
        params.active = active;
      }

      if (id) {
        params.id = id;
      }

      return params;
    } catch (error) {
      return {};
    }
  };

  const handleFiltersAndFetch = async ({ resetPage = true }) => {
    if (resetPage) {
      setPagination((prev) => ({ ...prev, page: 1 }));
    } else {
      setLoadingTable(true);

      const params = await getParamsFilter();

      fetchTransactions(params);
    }
  };

  const menu = (stationId, id) => (
    <Menu>
      <Menu.Item onClick={() => showConfirm(stationId, id)}>
        <span>{freire(texts.STOP)}</span>
      </Menu.Item>
    </Menu>
  );

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const showConfirm = (stationId, id) => {
    confirm({
      title: freire(texts.CONFIRM_SEND_STOP_COMMAND),
      content: freire(texts.CONFIRM_SEND_STOP_COMMAND_DESCRIPTION),
      onOk: async () => {
        try {
          const { data } = await apiOcpp.post(
            `ocpp16/remoteStopTransaction/${stationId}/transaction/${id}`
          );
          if (data.result === "Accepted") {
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
            await timeout(3000);

            fetchTransactions();
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
          }
        } catch (error) {
          notification.error({
            message: freire(texts.OOPS),
            description: freire(texts.UNEXPECTED),
          });
        }
      },
      onCancel() {},
    });
  };

  const clearFilters = () => {
    form.resetFields();
    handleFiltersAndFetch({ resetPage: true });
  };

  const { getFieldDecorator } = form;

  return (
    <div className="container">
      <Breadcrumbs
        breadcrumbs={[
          freire(texts.ADMIN),
          freire(texts.OCPP),
          freire(texts.CHARGES),
        ]}
      />
      <div className="filter">
        <Form wrapperCol={{ span: 24 }}>
          <Form.Item>
            <Row gutter={[16, 8]}>
              <Col span={4}>
                <Form.Item label={freire(texts.DISPLAY)}>
                  {getFieldDecorator("active", { initialValue: null })(
                    <Select>
                      <Option value={null}>{freire(texts.ALL)}</Option>
                      <Option value={"ativas"}>{freire(texts.ACTIVE)}</Option>
                      <Option value={"finalizadas"}>
                        {freire(texts.FINISHED)}
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={freire(texts.TRANSACTION_ID)}>
                  {getFieldDecorator("id")(
                    <Input
                      placeholder={freire(texts.TRANSACTION_ID_EX)}
                      type={"number"}
                      onPressEnter={() =>
                        handleFiltersAndFetch({ resetPage: true })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={freire(texts.START_PERIOD)}>
                  {getFieldDecorator("dateTime")(
                    <RangePicker
                      format="L"
                      placeholder={[
                        freire(texts.START_DATE),
                        freire(texts.FINAL_DATE),
                      ]}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>

        <div className="footer">
          {totalTransactions > 0 ? (
            <div style={{ paddingTop: 5 }}>
              {freire(texts.SHOWING)}{" "}
              <span className="stationsDisplayed">
                {freire(
                  texts.CHARGES_TOTAL(transactions.length, totalTransactions)
                )}
              </span>
            </div>
          ) : null}
          <div>
            <Button
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={clearFilters}
            >
              {freire(texts.CLEAN)}
            </Button>
            <Button
              onClick={() => handleFiltersAndFetch({ resetPage: true })}
              type="primary"
            >
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>
      </div>

      <TablePagination
        style={{
          backgroundColor: "white",
          cursor: canShowDetailsOcppCharge ? "pointer" : "default",
        }}
        scroll={{ x: 1300 }}
        tableLayout="auto"
        loading={loadingTable}
        rowKey={(data) => data.id}
        dataSource={transactions}
        columns={columns}
        onRow={(data) => {
          return {
            onClick: () =>
              canShowDetailsOcppCharge
                ? history.push(`/dashboard/ocpp/transactions/${data.id}`)
                : {},
          };
        }}
        current={pagination.page}
        total={totalTransactions}
        onPagination={setPagination}
      />
    </div>
  );
};

const PageListTransactions = Form.create({ name: "filters" })(ListTransactions);
export default withRouter(PageListTransactions);
