import CountryFlag from "../../../../components/SelectCountry/CountryFlag";
import { Country as ICountry } from "../../../../interfaces/country";
import { ICompany } from "../../../../interfaces/company";
import * as texts from "../../locales";


interface IColumnsProps {
    companies: ICompany[];
    countries: ICountry[];
    freire: any;
}

export default function getColumns ({companies, countries, freire}: IColumnsProps) {
  const RenderCompany = (companyId: string) => {
    return (
      companies?.find((company) => company._id === companyId)?.companyName ?? "----"
    )
  };

  const RenderCountry = (country_code: string) => {
    const country = countries?.find((country) => country.code === country_code);
    return country ? <CountryFlag country={country} /> : "----";
  };

  return [
    {
      title: freire(texts.NAME),
      dataIndex: "name",
      key: "name",
    },
    {
      title: freire(texts.COMPANY),
      dataIndex: "company_id",
      key: "company_id",
      render: RenderCompany,
    },
    {
      title: freire(texts.ADDRESS),
      dataIndex: "address",
      key: "address",
    },
    {
      title: freire(texts.COUNTRY),
      dataIndex: "country",
      key: "country",
      render: RenderCountry,
    },
  ];
};