export const ERROR_GET_USERS = {
  pt: "Erro ao buscar usuários",
  es: "Error al buscar usuarios",
  en: "Error searching users",
};

export const ERROR_DOWNLOAD_FILE = {
  pt: "Não foi possível fazer o download do arquivo",
  es: "No se pudo descargar el archivo",
  en: "It was not possible to download the file",
};

export const ERROR_CREATE_FILE = {
  pt: "Não foi possível gerar o arquivo para download",
  es: "No se pudo generar el archivo descargable",
  en: "It was not possible to generate the file for download",
};

export const ERROR_GET_CHARGES_USERS = {
  pt: "Erro ao buscar as cargas do usuário",
  es: "Error al buscar las cargas del usuario",
  en: "Error searching users' charges",
};

export const ERROR_GET_USERS_CARS = {
  pt: "Erro ao buscar os carros do usuário",
  es: "Error al buscar los automóviles del usuario",
  en: "Error searching users' cars",
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const USERS = {
  pt: "Usuários",
  es: "Usuarios",
  en: "Users",
};

export const SEARCH_BY_EMAIL = {
  pt: "Pesquise pelo email...",
  es: "Busque por el correo electrónico...",
  en: "Search by email...",
};

export const EMAIL_EXAMPLE = {
  pt: "nome@mail.com",
  es: "nombre@mail.com",
  en: "name@mail.com",
};

export const NAME_EXAMPLE = {
  pt: "Nome do usuário",
  es: "Nombre del usuario",
  en: "User name",
};

export const SEARCH_BY_DOCUMENT = {
  pt: "Pesquise pelo documento (CPF, DNI, etc)...",
  es: "Busque por el documento (CPF, DNI, etc)...",
  en: "Search by document (CPF, DNI, etc)...",
};

export const DOCUMENT_EXAMPLE = {
  pt: "CPF, DNI ou outros",
  es: "CPF, DNI o otros",
  en: "CPF, DNI or others",
};

export const SEARCH_BY_DOCUMENTS = {
  pt: "Você pode pesquisar por e-mail, nome, documento ou telefone.",
  es: "Puedes buscar por correo electrónico, nombre, documento o telefono.",
  en: "You can search by email, name, document or phone.",
};

export const SEARCH_BY_PHONE = {
  pt: "Pesquise pelo telefone...",
  es: "Busque por el telefono...",
  en: "Search by phone...",
};

export const PHONE_EXAMPLE = {
  pt: "(00) 91234-5678",
  es: "(00) 91234-5678",
  en: "(00) 91234-5678",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const SEARCH_DOCUMENTS = {
  pt: "Pesquisar por",
  es: "Buscar por",
  en: "Search for",
};
export const CLEAN = {
  pt: "Limpar",
  es: "Limpiar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const CSV_FILE_NAME = {
  pt: "usuarios_cargas.csv",
  es: "usuarios_cargas.csv",
  en: "users_charges.csv",
};

export const CSV_FILE_NAME_HISTORY = (DATA, USER_ID) => ({
  pt: `Histórico de cargas_${DATA} - Usuário_${USER_ID}`,
  es: `Historial de cargas_${DATA} - Usuario_${USER_ID}`,
  en: `User charges history_${DATA} - User_${USER_ID}`,
});

export const MONTH_TIME = {
  pt: "L HH[h]mm",
};

export const DOWNLOAD_CSV = {
  pt: "Baixar lista de usuários em Excel",
  es: "Descargar lista de usuarios en Excel",
  en: "Download users list in Excel",
};

export const DOWNLOAD_CHARGES_CSV = {
  pt: "Baixar cargas do usuário em Excel",
  es: "Descargar cargas del usuario en Excel",
  en: "Download users' charges in Excel",
};

// TODO: translate properly
export const LOADING_USER_CHARGES = {
  pt: "Buscando cargas do usuário ...",
  es: "Buscando cargas del usuario ...",
  en: "Searching users' charges ...",
};

export const ENERGY = {
  pt: "Energia",
  es: "Energía",
  en: "Energy",
};

export const CHARGING = {
  pt: "Carregando",
  es: "Cargando",
  en: "Charging",
};

export const FREE = {
  pt: "Grátis",
  es: "Gratis",
  en: "Free",
};

export const POS_PAYMENT = {
  pt: "Pagamento no caixa",
};

export const PAYMENT_METHOD = {
  pt: "Forma de pagamento",
  es: "Forma de pago",
  en: "Payment method",
};

export const NEGATIVE_BALANCE = {
  pt: "Saldo negativo",
  es: "Saldo negativo",
  en: "Negative balance",
};

export const KWH = (ENERGY) => ({
  pt: `${ENERGY} kWh`,
  es: `${ENERGY} kWh`,
  en: `${ENERGY} kWh`,
});

export const VALUE = {
  pt: "Valor total",
  es: "Valor total",
  en: "Amount",
};

export const STARTED_AT = {
  pt: "Inicio em",
  es: "Inicio en",
  en: "Started at",
};

export const ENDED_AT = {
  pt: "Término em",
  es: "Término en",
  en: "Ended at",
};

export const DURATION = {
  pt: "Duração carga",
  es: "Duración de la carga",
  en: "Charge duration",
};

export const IDLE_TIME = {
  pt: "Tempo ocioso",
  es: "Tiempo de inactividad",
  en: "Idle time",
};

export const STATION = {
  pt: "Estação",
  es: "Estación",
  en: "Station",
};

export const STATION_ID = {
  pt: "ID da estação",
  es: "ID de la estación",
  en: "Station ID",
};

export const CONNECTOR_ID = {
  pt: "ID do conector",
  es: "ID del conector",
  en: "Connector ID",
};

export const CHARGE_ID = {
  pt: "ID da carga",
  es: "ID de la carga",
  en: "Charge ID",
};

export const USER_ID = {
  pt: "ID do usuário",
  es: "ID del usuario",
  en: "User ID",
};

export const CREATED_AT = {
  pt: "Criado em",
  es: "Creado en",
  en: "Created at",
};

export const ENERGY_VALUE = {
  pt: "Valor energia",
  es: "Valor energético",
  en: "Energy value",
};

export const IDLE_VALUE = {
  pt: "Ociosidade",
  es: "tarifa inactiva",
  en: "Idle fee",
};

export const COUPON = {
  pt: "Cupom",
  es: "Cupón",
  en: "Coupon",
};

export const CLOSE = {
  pt: "Fechar",
  es: "Cerrar",
  en: "Close",
};

export const LOADING_USER_CARS = {
  pt: "Buscando carros do usuário ...",
  es: "Buscando automóviles del usuario ...",
  en: "Searching users' cars ...",
};

export const ZIP_CODE = {
  pt: "CEP",
  es: "CPA",
  en: "Zip Code",
};

// TODO: address 1, address 2
export const PUBLIC_PLACE = {
  pt: "Logradouro",
  es: "Calle",
  en: "Public place",
};

export const NUMBER = {
  pt: "Número",
  es: "Número",
  en: "Number",
};

export const NEIGHBORHOOD = {
  pt: "Bairro",
  es: "Barrio",
  en: "Neighborhood",
};

export const COMPLEMENT = {
  pt: "Complemento",
  es: "Complemento",
  en: "Complement",
};

export const CITY = {
  pt: "Cidade",
  es: "Ciudad",
  en: "City",
};

export const STATE = {
  pt: "Estado",
  es: "Provincia",
  en: "State",
};

export const CITY_ID = {
  pt: "ID cidade",
  es: "ID ciudad",
  en: "City ID",
};

export const STATE_ID = {
  pt: "ID estado",
  es: "ID provincia",
  en: "State ID",
};

export const STATE_UF = {
  pt: "UF",
  es: "Código de provincia",
  en: "Federation Unit State",
};

export const COUNTRY_CODE = {
  pt: "Código do país",
  es: "Código del país",
  en: "Country code",
};

export const CURRENCY_CODE = {
  pt: "Código da moeda",
  es: "Código de la moneda",
  en: "Currency code",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const FULL_NAME = {
  pt: "Nome completo",
  es: "Nombre completo",
  en: "Full name",
};

export const EMAIL = {
  pt: "Email",
  es: "Correo electrónico",
  en: "Email",
};

export const CHARGES = {
  pt: "Cargas",
  es: "Cargas",
  en: "Charges",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const SEE_CHARGES = {
  pt: "Ver Cargas",
  es: "Ver Cargas",
  en: "See Charges",
};

export const SEE_CARS = {
  pt: "Ver Carros",
  es: "Ver automóviles",
  en: "See Cars",
};

export const TOTAL_CHARGES = {
  pt: "Total de cargas",
  es: "Cargas totales",
  en: "Total of charges",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const MANAGEMENT = {
  pt: "Administração",
  es: "Administración",
  en: "Administration",
};

export const NEW = {
  pt: "Nova",
  es: "Nueva",
  en: "New",
};

export const EDIT_COMPANY = {
  pt: "Editar empresa",
  es: "Editar empresa",
  en: "Edit company",
};

export const PINS_CUSTOMIZED = {
  pt: "Pin customizado?",
  es: "¿Pin personalizado?",
  en: "Customized pin?",
};

export const AGO = (duration) => ({
  pt: `${duration} atrás`,
  es: `${duration} atrás`,
  en: `${duration} ago`,
});

export const AGE = {
  pt: "Idade",
  es: "Edad",
  en: "Age",
};

export const YEARS_OLD = (years) => ({
  pt: `${years} ano${years === 1 ? "" : "s"}`,
  es: `${years} año${years === 1 ? "" : "s"}`,
  en: `${years} year${years === 1 ? "" : "s"}`,
});

export const YES = {
  pt: "Sim",
  es: "Sí",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SHOW_DETAILS_EDIT = {
  pt: "Ver detalhes | Editar",
  es: "Ver detalles | Editar",
  en: "See details | Edit",
};

export const RECIPIENT = {
  pt: "Recebedor",
  es: "Receptor",
  en: "Recipient",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Está bien!",
  en: "All set!",
};

export const WEN_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const REQUIRED = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const RECIPIENTS = {
  pt: "Recebedores",
  es: "Receptores",
  en: "Recipients",
};

export const SEARCH_BY_NAME = {
  pt: "Pesquise pelo nome...",
  es: "Busque por el nombre...",
  en: "Search by name...",
};

export const RECIPIENT_DATA = {
  pt: "Dados do recebedor",
  es: "Datos del receptor",
  en: "Recipient data",
};

export const BANK_DATA = {
  pt: "Dados bancários:",
  es: "Datos bancarios:",
  en: "Bank data:",
};

export const AGENCY = {
  pt: "Agencia",
  es: "Agencia",
  en: "Agency",
};

export const ACCOUNT = {
  pt: "Conta",
  es: "Cuenta",
  en: "Account",
};

export const BANK_CODE = {
  pt: "Código do banco",
  es: "Código del banco",
  en: "Bank code",
};

export const AGENCY_CHECK_DIGIT = {
  pt: "Dígito verificador da agência",
  es: "Dígito verificador de la agencia",
  en: "Agency verifier digit",
};

export const ACCOUNT_TYPE = {
  pt: "Tipo da conta",
  es: "Tipo de cuenta",
  en: "Account type",
};

export const ACCOUNT_VERIFICATION_DIGIT = {
  pt: "Dígito verificador da conta",
  es: "Dígito verificador de la cuenta",
  en: "Account verifier digit",
};

export const FULL_NAME_BUSINESS_NAME = {
  pt: "Nome completo ou razão social",
  es: "Nombre completo o razón social",
  en: "Complete name or corporate name",
};

export const ACCOUNT_CPF_OR_CNPJ = {
  pt: "CPF ou CNPJ da conta",
  es: "CPF o CNPJ de la cuenta",
  en: "Account CPF or CNPJ",
};

export const SHOW_DETAILS = {
  pt: "Ver detalhes",
  es: "Ver detalles",
  en: "See details",
};

export const COUPON_USE = {
  pt: "Utilização",
  es: "Usar",
  en: "Use",
};

export const COUPON_DATA = {
  pt: "Dados do cupom",
  es: "Detalles del cupón",
  en: "Coupon details",
};

export const RECIPIENT_ADD_SUCCESS = {
  pt: "Recebedor adicionado com sucesso!",
  es: "¡Receptor añadido con éxito!",
  en: "Successfully added recipient!",
};

export const DATA_OF_RECEIPT = {
  pt: "Dados para recebimento",
  es: "Datos para la recepción",
  en: "Receipt data",
};

export const RECEIVER_NAME = {
  pt: "Nome do recebedor",
  es: "Nombre del destinatario",
  en: "Recipient name",
};

export const ANTICIPABLE_DESCRIPTION = {
  pt: "Essa propriedade define a porcentagem referente ao valor a receber que o recebedor pode antecipar junto ao Pagar.me. Por padrão, novos recebedores possuem essa propriedade com valor 0.",
  es: "Esta propiedad define el porcentaje referente al valor a recibir y que el destinatario puede anticipar junto a Pagar.me. Por defecto, los nuevos destinatarios tienen esta propiedad con valor 0.",
  en: "This property defines the percentage referring to the amount receivable that the recipient can anticipate with Pagar.me. By default, new recipients have this property set to 0.",
};

export const AUTOMATIC_ANTICIPATION_DESCRIPTION = {
  pt: "Define se o recebedor pode receber antecipações automáticas.",
  es: "Define si el destinatario puede recibir anticipos automáticos.",
  en: "Defines whether the recipient can receive automatic prepayments.",
};

export const TRANSFER_DAY_DESCRIPTION = {
  pt: "Essa propriedade define em qual dia as transferências automáticas são feitas para o recebedor, dado o transfer_interval escolhido. Os valores possíveis são: 1 a 5 se transfer_interval for igual a weekly. 1 a 31 se transfer_interval for igual a monthly. 0 se transfer_interval for igual daily.",
  es: "Esta propiedad define en qué día se realizan las transferencias automáticas al destinatario, dado el transfer_interval elegido. Los valores posibles son: 1 a 5 si el intervalo de transferencia es semanal. 1 a 31 si el intervalo de transferencia es mensual. 0 si el intervalo de transferencia es diario.",
  en: "This property defines on which day automatic transfers are made to the recipient, given the chosen transfer_interval. Possible values ​​are: 1 to 5 if transfer_interval equals weekly. 1 to 31 if transfer_interval equals monthly. 0 if transfer_interval equals daily.",
};

export const TRANSFER_ENABLED = {
  pt: "Essa propriedade define se o recebedor pode receber seus pagamentos automaticamente, no dia e no intervalo que forem definidos.",
  es: "Esta propiedad define si el detinatario puede recibir sus pagos automáticamente, el día y el intervalo que se establecen.",
  en: "This property defines whether the recipient can receive his payments automatically, on the day and at the interval that is defined.",
};

export const ACCOUNT_NUMBER = {
  pt: "Número da conta",
  es: "Número de cuenta",
  en: "Account number",
};

export const CHECKING_ACCOUNT = {
  pt: "Conta corrente",
  es: "Cuenta corriente",
  en: "Current account",
};

export const SAVING_ACCOUNT = {
  pt: "Conta poupança",
  es: "Cuenta de ahorro",
  en: "Savings account",
};

export const JOINT_CHECKING_ACCOUNT = {
  pt: "Conta corrente conjunta",
  es: "Cuenta corriente conjunta",
  en: "Joint current account",
};

export const JOINT_SAVING_ACCOUNT = {
  pt: "Conta poupança conjunta",
  es: "Cuenta de ahorro conjunta",
  en: "Joint savings account",
};

export const ERROR_EDIT_USER = {
  pt: "Erro ao editar o usuário",
  es: "Error al editar el usuario",
  en: "Error editing the user",
};

export const VERIFY_DATA_TRY_AGAIN = {
  pt: "Verifique as informações e tente novamente",
  es: "Compruebe las informaciones e inténtelo nuevamente",
  en: "Check the information's and try again",
};

export const GET_GROUP_PERMISSION_USERS = {
  pt: "Erro ao buscar o grupo de permissão do usuário",
};
export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const SELECT_COMPANY = {
  pt: "Selecione a empresa",
  en: "Select the company",
  es: "Seleccione la empresa",
};

export const SELECT_GROUP = {
  pt: "Selecione o grupo",
};

export const USER_CARS = {
  pt: "Carros do usuário",
  es: "Automóviles del usuario",
  en: "User cars",
};

export const COMPANY = {
  pt: "Empresa",
  es: "Empresa",
  en: "Company",
};

export const CAMPAIGN = {
  pt: "Campanha",
  es: "Campaña",
  en: "Campaign",
};

export const DISCOUNT = {
  pt: "Desconto",
  en: "Discount",
  es: "Descuento",
};

export const PARENT_COMPANY = {
  pt: "Empresa mãe",
  es: "Empresa madre",
  en: "Parent company",
};

export const SELECT_PARENT_COMPANY = {
  pt: "Selecione a empresa mãe",
  es: "Seleccione la empresa madre",
  en: "Select the parent company",
};

export const URL_POWERBI = {
  pt: "Url PowerBI",
};

export const WHITE_LABEL = {
  pt: "White Label",
};

export const SELECT_RECIPIENT = {
  pt: "Selecione um recebedor",
  es: "Seleccione un destinatario",
  en: "Select a recipient",
};

export const PAY_CHARGE_SUCCESS = {
  pt: "Pagamento efetuado com sucesso!",
  es: "Pago realizado con exito!",
  en: "Payment done successfully!",
};

export const PAY_CHARGE_ERROR = {
  pt: "Não foi possível efetuar o pagamento!",
  es: "No se pudo realizar el pago!",
  en: "Could not make the payment!",
};

export const CONFIRM_PAY_CHARGE = {
  pt: "Deseja realmente reprocessar o pagamento?",
  es: "¿Desea realmente reprocesar el pago?",
  en: "Do you really want to reprocess the payment?",
};

export const PAY_CHARGE = {
  pt: "Reprocessar o pagamento",
  es: "Reprocesar el pago",
  en: "Do you really want to reprocess the payment?",
};

export const ACCESS_GROUP_PERMISSIONS = {
  pt: "Grupo de permissões de acesso",
  es: "Grupo de permisos de acceso",
  en: "Access group permissions",
};

export const EDIT_USER = {
  pt: "Editar usuário",
  es: "Editar usuario",
  en: "Edit user",
};

export const ERROR_GET_DETAILS_USER = {
  pt: "Erro ao buscar as informações do usuário",
  es: "Error al buscar los detalles del usuario",
  en: "Error getting user details",
};

export const ERROR_GET_DETAILS_COUPON = {
  pt: "Erro ao buscar as informações do cupom",
  es: "Error al buscar los detalles del cupón",
  en: "Error getting coupon details",
};

export const ANTIFRAUD_CHECK_CHANGED = (status) => ({
  pt: `Verificação antifraude ${
    status ? "ativada" : "desativada"
  } para esse usuário`,
});

export const ANTIFRAUD_CHECK_CHANGED_ERROR = {
  pt: "Erro ao alterar verificação antifraude para esse usuário",
  es: "Error al cambiar la verificación antifraude para este usuario",
  en: "Error changing antifraud check for this user",
};

export const PERSONAL_DATA = {
  pt: "Dados pessoais",
  es: "Datos personal",
  en: "Personal data",
};

export const ASSOCIATECUPONS_DATA = {
  pt: "Cupons vinculados",
  es: "Cupóns vinculados",
  en: "Coupons linked",
};

export const LOADING_USER_DETAILS = {
  pt: "Buscando as informações do usuário ...",
  es: "Buscando detalles del usuario ...",
  en: "Loading user details ...",
};

export const LOADING_CUPON_DETAILS = {
  pt: "Buscando as informações dos cupons vinculados ...",
  es: "Buscando detalles de los cupóns vinculados ...",
  en: "Loading coupon details ...",
};

export const DOCUMENT = {
  pt: "CPF",
  es: "CIF",
  en: "ID",
};

export const VALIDATE_DOCUMENT = {
  pt: "Validar documento",
  es: "Validar documento",
  en: "Validate document",
};

export const BIRTHDATE = {
  pt: "Data de nascimento",
  es: "Fecha de nacimiento",
  en: "Birthdate",
};

export const PHONE = {
  pt: "Telefone",
  es: "Teléfono",
  en: "Phone",
};

export const ADDRESS = {
  pt: "Endereço",
  es: "Dirección",
  en: "Address",
};

export const USER_ANTIFRAUD_CHECK = {
  pt: "Verificação antifraude ativada para este usuário",
  es: "Verificación antifraude activada para este usuario",
  en: "Antifraud check activated for this user",
};

export const PAID = {
  pt: "Paga",
  es: "Paga",
  en: "Paid",
};

export const NOT_PAID = {
  pt: "Não paga",
  es: "No paga",
  en: "Not paid",
};

export const INVALID = {
  pt: "Inválido",
  en: "Invalid",
  es: "Inválido",
};

// TODO: exportar textos de data de nascimento e forma de pagamento
export const INVALID_FEMALE = {
  pt: "Inválida",
  en: "Invalid",
  es: "Inválida",
};

export const CHARGES_COUNT = (count) => ({
  pt: `${count > 0 ? `${count} ` : ""}Carga${count === 1 ? "" : "s"}`,
  es: `${count > 0 ? `${count} ` : ""}Carga${count === 1 ? "" : "s"}`,
  en: `${count > 0 ? `${count} ` : ""}Charge${count === 1 ? "" : "s"}`,
});

export const USER_EMPTY_CHARGES = {
  pt: "O usuário ainda não fez nenhuma carga.",
  es: "El usuario todavía no ha realizado ninguna carga.",
  en: "The user has not yet made any charge.",
};

export const SEE_ON_OCPP = (transactionID) => ({
  pt: `Ver no OCPP (ID: ${transactionID})`,
  es: `Ver en el OCPP (ID: ${transactionID})`,
  en: `See on OCPP (ID: ${transactionID})`,
});

export const RFID_TAG = {
  pt: "Tag RFID",
  es: "Etiqueta RFID",
  en: "RFID Tag",
};

export const APP = {
  pt: "Aplicativo",
  es: "Aplicación",
  en: "App",
};

export const APPS = {
  pt: "Aplicativos",
  es: "Aplicacións",
  en: "Apps",
};

export const COPY = {
  pt: "Copiar",
  es: "Copiar",
  en: "Copy",
};

export const COPIED = {
  pt: "Copiado com sucesso!",
  es: "¡Copiado con éxito!",
  en: "Copied successfully!",
};

export const USER_EMPTY_COUPONS = {
  pt: "Nenhum cupom vinculado",
  es: "Ningun cupón vinculado",
  en: "No coupon linked",
};

export const COUPON_CODE = {
  pt: "Verificar",
  es: "Verificar",
  en: "To check",
};

export const COUPON_INFO = {
  pt: "Dados do Cupom",
  es: "Información del cupón",
  en: "Coupon info",
};

export const COUPON_USAGE = {
  pt: "Utilização",
  es: "Utilización",
  en: "Usage",
};

export const EXPIRED = {
  pt: "Expirado",
  es: "Venció",
  en: "Expired",
};

export const IN_USE = {
  pt: "Em uso",
  es: "En uso",
  en: "In use",
};

export const INACTIVE = {
  pt: "Inativo",
  es: "Inactivo",
  en: "Inactive",
};

export const EXPIRATION = {
  pt: "Expiração",
  es: "Vencimiento",
  en: "Expiration",
};

export const COUPON_STATUS = {
  pt: "Status",
  es: "Estado",
  en: "Status",
};

export const COUPON_ACTIONS = {
  pt: "Ação",
  es: "Acción",
  en: "Action",
};

export const COUPON_UNLINK = {
  pt: "Desvincular cupom",
  es: "Desvincular cupón",
  en: "Unlink coupon",
};

export const UNLINK = {
  pt: "Desvincular",
  es: "Desvincular",
  en: "Unlink",
};

export const LANGUAGE = {
  pt: "Idioma",
  // TODO: es
  // TODO: en
};

export const ARE_YOU_SURE_UNLINK_COUPON = {
  pt: "Você tem certeza que deseja desvincular este cupom do usuário?",
  es: "Está seguro que desea desvincular este cupón de este usuario?",
  en: "Are you sure you want to unlink this coupon from this user?",
};

export const COUPON_UNLINK_SUCCESS = {
  pt: "O cupom foi desvinculado do usuário",
  es: "El cupón fue desvinculado del usuario",
  en: "The coupon was unlinked from the user",
};

export const OK_COUPON = {
  pt: "Tudo certo!",
  es: "¡Todo salió bien!",
  en: "All good!",
};

export const ERROR_UNLINK_COUPON = {
  pt: "Erro ao desvincular o cupom!",
  es: "¡Error al desvincular el cupón!",
  en: "Error to unlink coupon!",
};

export const IS_PERSON_FRAUDSTER = {
  pt: "Checagem Fraude",
  es: "Chequeo de fraude",
  en: "Check fraudster",
}