import React, { memo, useCallback, useEffect, useState } from "react";
import { WrappedFormUtils } from "antd/lib/form/Form";
import BrasilSelectLocation from "./Brasil";
import ArgentinaSelectLocation from "./Argentina";
import * as requests from "../../requests";
import * as texts from "./locales";
import { notification } from "antd";
import { useFreire } from "../../utils/freireContext";
import { useOnMount, useOnUpdate } from "../../hooks";
import { COUNTRY_AR, COUNTRY_BR } from "../../constants/countries";

interface ISelectLocation {
  form: WrappedFormUtils<any>;
  countryCode: string;
  currentLocation?: {
    state?: string;
    city?: string;
    province?: string;
  };
  disabled?: boolean;
  required?: boolean;
}

const SelectLocation: React.FC<ISelectLocation> = ({
  form,
  countryCode,
  currentLocation,
  disabled = false,
  required = false,
}) => {
  const { freire } = useFreire();

  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);

  const fetchStates = useCallback(async () => {
    if (!countryCode) return;

    try {
      setStates([]);
      setLoadingStates(true);

      const states = await requests.getStatesByCountry(countryCode);

      setStates(states);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_STATES),
      });
    } finally {
      setLoadingStates(false);
    }
  }, [countryCode, freire]);

  const fetchCities = useCallback(
    async (state: string) => {
      try {
        setCities([]);
        setLoadingCities(true);

        const cities = await requests.getCitiesByState(countryCode, state);

        setCities(cities);
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_GET_CITIES),
        });
      } finally {
        setLoadingCities(false);
      }
    },
    [countryCode, freire]
  );

  const setCurrentLocationOnEdit = () => {
    if (currentLocation) {
      const { state, city, province } = currentLocation;
      form.setFieldsValue({ address2: { state, city, province } });
    }
  };

  useOnMount(setCurrentLocationOnEdit);

  const loadAddress2 = useCallback(() => {
    const state = form.getFieldValue("address2.state");
    const city = form.getFieldValue("address2.city");
    const province = form.getFieldValue("address2.province");

    console.log("address:",state, city, province);
  }, [form]);

  useOnUpdate(loadAddress2);

  useEffect(() => {
    fetchStates();
  }, [countryCode, fetchStates]);

  if (countryCode === COUNTRY_BR) {
    return (
      <BrasilSelectLocation
        required={required}
        form={form}
        disabled={disabled}
        loadingStates={loadingStates}
        loadingCities={loadingCities}
        states={states}
        cities={cities}
        onStateChange={fetchCities}
      />
    );
  }

  if (countryCode === COUNTRY_AR) {
    return (
      <ArgentinaSelectLocation
        required={required}
        form={form}
        disabled={disabled}
        loadingProvinces={loadingStates}
        loadingCities={loadingCities}
        provinces={states}
        cities={cities}
        onProvinceChange={fetchCities}
      />
    );
  }

  return null;
};

export default memo(SelectLocation);
