import { useCallback, useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import GenerateFilter from "../../../components/GenerateFilter";
import TablePagination, { paginationInitConfig } from "../../../components/TablePagination";
import { Button, notification, Form } from "antd";
import * as texts from "../locales";
import { useFreire } from "../../../utils/freireContext";
import { sorter } from "../../../utils/sorter";
import api from "../../../services/api";
import * as requests from "../../../requests/index";
import { Country as ICountry } from "../../../interfaces/country";
import { ICompany } from "../../../interfaces/company";
import { ILocation } from "../../../interfaces/location";
import getColumns from "./components/Columns";
import { generateFields } from "./fields";
import withPermission from "../../../contexts/PermissionContext/withPermission";

function ListLocations(props: any) {
  const { freire } = useFreire();

  const [pagination, setPagination] = useState(paginationInitConfig)
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<ILocation[]>();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [countries, setCountries] = useState<ICountry[]>();

  const goNewLocation = () => {
    props.history.push("/dashboard/new_location");
  };

  const getLocations = useCallback( async (params = {}) => {
    setLoading(true);
    try{
      const { data: locations } = await api.get("locations", {
        params
      });
      setLocations(locations);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_LOCATIONS),
      });
    } finally {
      setLoading(false);
    }
  }, [freire]);

  const getCompanys = useCallback( async () => {
    setLoading(true);
    try{
      const { data: companiesData } = await api.get("company");
      const companies = sorter(companiesData, "companyName");

      const companiesNotHolding = companies.filter((company: ICompany) => !company.holding);
      const options = companies.map((company: ICompany) => ({
        label: company.companyName,
        value: company._id,
      }))

      setCompanies(companiesNotHolding);
      setCompanyOptions(options);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_COMPANIES),
      });
    } finally {
      setLoading(false);
    }
  }, [freire]);

  const getCountries = useCallback( async () => {
    setLoading(true);
    try{
      const countries = await requests.getRecommendedCountries();
      setCountries(countries);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_COUNTRIES),
      });
    } finally {
      setLoading(false);
    }
  }, [freire]);

  useEffect(() => {
    getLocations();
    getCompanys();
    getCountries();
  }, [getLocations, getCompanys, getCountries]);

  const onChangeCompany = () => {
    props.form.resetFields();
  }

  const onFilter = () => {
    props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        getLocations({...values});
      }
    });
  };

  const columns = getColumns({companies: companies!, countries: countries!, freire: freire});

  const filteredFilds = generateFields(companyOptions, onChangeCompany, freire);

  return (
    <div className="container">
      <div className="header">
        <Breadcrumbs 
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.STATIONS),
            freire(texts.LOCATIONS),
          ]}
          />
        <Button
          className="buttonAddLocation"
          type="primary"
          onClick={goNewLocation}
        >
          {freire(texts.NEW_LOCATION)}
        </Button>
      </div>
      <GenerateFilter
        form={props.form}
        fields={filteredFilds}
        handleClear={() => {
          props.form.resetFields();
        }}
        handleFilter={onFilter}
      />
      <TablePagination
        style={{ backgroundColor: "white" }}
        scroll={{ x: 1300 }}
        tableLayout="auto"
        loading={loading}
        current={pagination.page}
        rowKey={(data: any) => data._id}
        dataSource={locations}
        columns={columns}
        onPagination={(pagination: any) => setPagination(pagination)}
        bodyStyle={undefined}
      />
    </div>
  );
};

const PageListLocations = Form.create({ name: "filters" })(ListLocations)

export default withPermission(PageListLocations);
