import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Radio,
  Row,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Show } from "../../../contexts/PermissionContext";
import api from "../../../services/api";
import EditDrawer from "../../../components/StationEditDrawer/EditDrawer";
import "./index.css";
import * as texts from "../locales";
import { FreireContext } from "../../../utils/freireContext";
import { sorter } from "../../../utils/sorter";
import { getCompanyCurrentUser } from "../../../services/auth";

class ListStations extends Component {
  static contextType = FreireContext;

  state = {
    name: "seila",
    loadingTable: false,
    visibleEditDrawer: false,
    loadingEditDrawer: false,
    data: [],
    station: [],
    dateFiltrada: [],
    tablePage: 1,
    companies: [],
    companyOptions: [],
    companyID: "",
    preventPageReload: false,
  };

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.NAME),
        dataIndex: "name",
        key: "name",
      },
      {
        title: freire(texts.ADDRESS),
        dataIndex: "address",
        key: "address",
      },
      {
        title: freire(texts.STATUS),
        dataIndex: "disabled",
        key: "disabled",
        render: (text, data) => (
          <Icon type={data.disabled ? "close" : "check"} />
        ),
      },
      {
        title: freire(texts.PLUGS),
        dataIndex: "plugs",
        key: "plugs",
        render: (plugs) => (
          <span>
            {plugs.map((plug) => (
              <Tag key={plug}>{plug}</Tag>
            ))}
          </span>
        ),
      },
      {
        title: freire(texts.CONNECTED),
        dataIndex: "stationID",
        key: "stationID",
        render: (text, data) => (
          <Icon type={data.stationID ? "check" : "close"} />
        ),
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: 120,
        render: (text, data) => (
          <>
            <Show when={"edit-station-not-connected"}>
              <Tooltip
                placement="top"
                title={`${freire(texts.SHOW_DETAILS)} | ${freire(texts.EDIT)}`}
              >
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => this.openDrawer(data)}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
            </Show>

            <Show when={"delete-station-not-connected"}>
              <Tooltip placement="top" title={freire(texts.DELETE)}>
                <Popconfirm
                  placement="top"
                  title={freire(texts.QUESTION_DELETE)}
                  okText={freire(texts.YES)}
                  cancelText={freire(texts.NO)}
                  onConfirm={() => this.onDeleteStation(data)}
                >
                  <Button shape="circle" size="small" ghost type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Show>
          </>
        ),
      },
    ];
  };

  componentDidMount() {
    this.getStations();
    this.getAllCompanies();
    this.setCompanyID();
  }

  async getAllCompanies() {
    const { data } = await api.get("company");

    const companies = sorter(data, "companyName");

    const companiesNotHolding = companies.filter((company) => !company.holding);

    this.setState({
      companies: companiesNotHolding,
      companyOptions: companies.map((company) => ({
        label: company.companyName,
        value: company._id,
      })),
    });
  }

  async setCompanyID() {
    try {
      const company = await getCompanyCurrentUser();
      if (company && company.id) {
        this.setState({ companyID: company.id });
      }
    } catch (error) {}
  }

  async getStations(params = {}) {
    this.setState({ loadingTable: true });

    const { data } = await api.get(`stations`, { params });
    this.setState({ dateFiltrada: sorter(data), loadingTable: false });
  }

  filtroDate = async (nome) => {
    this.setState({ loadingTable: true });

    const { data } = await api.get("stations", {
      params: { name: nome },
    });
    this.setState({ dateFiltrada: sorter(data), loadingTable: false });
  };

  openDrawer = (station) => {
    this.setState({ visibleEditDrawer: true, station });
  };

  closeDrawer = () => {
    this.setState({ visibleEditDrawer: false });
  };

  onSalveStation = async (payload = {}) => {
    const { freire } = this.context;

    this.setState({ loadingEditDrawer: true }, async () => {
      try {
        if (this.state.station._id) {
          if (payload.plugsConcurrently === undefined) delete payload.plugsConcurrently;

          await api.put(`stations/${this.state.station._id}`, payload);

          this.setState({
            loadingEditDrawer: false,
            visibleEditDrawer: false,
          });
          notification.success({
            message: freire(texts.ALL_RIGHT),
            description: freire(texts.STATION_EDITED),
          });
          this.getStations();
        }
      } catch (error) {
        const {
          response: {
            data: { message },
          },
        } = error;

        this.setState({ loadingEditDrawer: false });

        notification.error({
          message: freire(texts.WENT_WRONG),
          description: message || error.message,
        });
      }
    });
  };

  onDeleteStation = async (data) => {
    const { freire } = this.context;

    try {
      if (data._id) {
        await api.delete(`stations/${data._id}`);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.STATION_DELETED),
        });

        this.getStations();
      }
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: message || error.message,
      });
    }
  };

  filter = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.props.form.validateFields((err, values) => {
      if (!values.name) values.name = undefined;
      if (!values.address) values.address = undefined;

      if (!err) {
        this.getStations(values);
      }
    });

    this.setState({ tablePage: 1, preventPageReload: true });
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.getStations();
  };

  goNew = () => {
    this.props.history.push("/dashboard/new_station");
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visibleEditDrawer,
      loadingEditDrawer,
      station,
      companyID,
      companies,
    } = this.state;
    const { freire } = this.context;

    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.STATIONS),
            freire(texts.LISTING),
          ]}
        />
        <div className="filter">
          <Form wrapperCol={{ span: 24 }}>
            <Form.Item>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {getFieldDecorator("name")(
                  <Input
                    className="firstInput"
                    placeholder={freire(texts.SEARCH_BY_NAME)}
                    onKeyPress={this.handleKeyPress}
                  />
                )}
                <Button
                  icon="search"
                  className="buttonSearch"
                  onClick={(e) => this.filter(e)}
                >
                  {freire(texts.SEARCH)}
                </Button>
                <Show when={"add-station-not-connected"}>
                  <Button
                    icon="plus"
                    className="buttonNew"
                    onClick={this.goNew}
                    type="primary"
                  >
                    {freire(texts.NEW)}
                  </Button>
                </Show>
              </div>
            </Form.Item>
            <Row gutter={[16, 8]}>
              <Col span={6}>
                <Form.Item label={freire(texts.ADDRESS)}>
                  {getFieldDecorator("address")(
                    <Input
                      placeholder={freire(texts.ADDRESS)}
                      onKeyPress={this.handleKeyPress}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={freire(texts.DISABLED)}>
                  {getFieldDecorator("disabled")(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={true}>
                        {freire(texts.YES)}
                      </Radio.Button>
                      <Radio.Button value={undefined}>---</Radio.Button>
                      <Radio.Button value={false}>
                        {freire(texts.NO)}
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAR)}
            </Button>
            <Button onClick={(e) => this.filter(e)} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          scroll={{ x: 1300 }}
          tableLayout="auto"
          loading={this.state.loadingTable}
          rowKey={(data) => data._id}
          dataSource={this.state.dateFiltrada}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />

        <EditDrawer
          visible={visibleEditDrawer}
          loading={loadingEditDrawer}
          onClose={() => this.closeDrawer()}
          onSave={(values) => this.onSalveStation(values)}
          station={station}
          companyID={companyID}
          companies={companies}
        />
      </div>
    );
  }
}
const PageListStations = Form.create({ name: "filters" })(ListStations);
export default withRouter(PageListStations);
