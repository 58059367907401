import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, notification, Row, Select, Input } from "antd";
import apiOcpp from "../../../../services/apiOcpp";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";
import api from "../../../../services/api";

const { Option } = Select;

const ID_TAG = "TUPI_ADMIN_TAG";

const RemoteStartTransaction = ({
  stationId = "",
  connectors = [],
  redirectTo = () => {},
  form,
}) => {
  useEffect(() => {
    form.resetFields(["connectorId"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectors]);

  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const { freire } = useFreire();

  const send = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        setLoading(true);
        const { data } = await apiOcpp.post(
          `/ocpp16/remoteStartTransaction/${stationId}`,
          {
            connectorId: payload.connectorId || 1,
          }
        );

        setLoading(false);

        if (data && data.result) {
          if (data.result === "Accepted") {
            let success = false;
            let chargerHistory;
            if(payload.remoteChargeEmail !== "") {
              setLoading(true);
              const resp = await api.post("/admin/create-remote-charge", {
                transactionID: data.transactionID,
                email: payload.remoteChargeEmail?.trim(),
                couponCod: payload.remoteChargeCouponCod?.trim(),
              })
              success = resp.data.success
              chargerHistory = resp.data.chargerHistory[0];
              setLoading(false);
            }
            var description = freire(texts.CHARGE_STARTED);
            if (data.transactionID) {
              description += ` ID: ${data.transactionID}`;
            }
            if (success) {
              description += ` ${freire(texts.ATTACH_REMOTE_CHARGE_SUCCESS)} ${chargerHistory.userEmail}`;
              if (chargerHistory.stopDateTime == null) {
                description += ` ${freire(texts.CHARGE_HISTORY_CURRENTLY_CHARGING)}`;
              };
            } else {
              description += ` ${freire(texts.ATTACH_REMOTE_CHARGE_FAILED)}`;
            }
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description,
            });
            redirectTo(`/dashboard/ocpp/transactions/${data.transactionID}`);
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: `${freire(texts.STATION_RESPONSE)}: '${
                data.result
              }'`,
            });
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        setLoading(false);
        console.error(error)
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.VERIFY_AGAIN),
        });
      }
    });
  };

  return (
    <Fragment>
      <Form layout="vertical" wrapperCol={{ span: 24 }}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.CONNECTOR_ID)}>
              {getFieldDecorator("connectorId", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Select
                  disabled={!stationId}
                  placeholder={freire(texts.SELECT_CONNECTOR_ID)}
                >
                  {connectors.map((connector) => (
                    <Option
                      key={connector.connectorId}
                      value={connector.connectorId}
                    >
                      ID {connector.connectorId}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.TAG_OCPP)}>
              {getFieldDecorator("idTag", {
                initialValue: ID_TAG,
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Select placeholder={freire(texts.SELECT_TAG_OCPP)} disabled>
                  <Option value={ID_TAG}>{ID_TAG}</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.REMOTE_CHARGE_EMAIL)}>
              {getFieldDecorator("remoteChargeEmail")(<Input/>)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.REMOTE_CHARGE_COUPON)}>
              {getFieldDecorator("remoteChargeCouponCod")(<Input/>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          disabled={!stationId || loading}
          loading={loading}
          onClick={send}
          type="primary"
        >
          <span>
            {freire(texts.EXECUTE)}{" "}
            <span style={{ fontWeight: "bold" }}> RemoteStartTransaction</span>
          </span>
        </Button>
      </div>
    </Fragment>
  );
};

const PageRemoteStartTransaction = Form.create({ name: "inputs" })(
  RemoteStartTransaction
);

export default PageRemoteStartTransaction;
