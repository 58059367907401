const generateFields = ({
  companyOptions,
  onChangeCompany,
  stationsOptions,
}) => [
  {
    label: "Empresas",
    name: "company_ids",
    component: "tupi.select",
    isMultiple: true,
    placeholder: "Selecione as empresas",
    options: companyOptions,
    onChange: onChangeCompany,
    colSpan: 8,
  },
  {
    label: "Estações",
    name: "station_ids",
    component: "tupi.select",
    isMultiple: true,
    placeholder: "Selecione as estações",
    options: stationsOptions,
    colSpan: 14,
    help: "Você pode pesquisar uma estação pelo ID, nome ou endereço. ",
  },

  {
    label: "Conexão",
    name: "disabled",
    component: "tupi.radioGroup",
    options: [
      { value: undefined, label: "Todas" },
      { value: false, label: "Ativas" },
      { value: true, label: "Desativadas" },
    ],
    colSpan: 6,
  },
  {
    label: "Visibilidade",
    name: "visibility",
    initialValue: "all",
    component: "tupi.radioGroup",
    options: [
      { value: undefined, label: "Todos" },
      { value: "groups", label: "Grupos" },
      { value: "none", label: "Ocultas" },
    ],
    when: "filter-by-visibility-station",
    colSpan: 6,
  },
];

export default generateFields;
